import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { ToFixedPipe } from './to-fixed.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { FormArrayFilterPipe } from './form-array-filter.pipe';
import { SortPipe } from './sort.pipe';
import { MakePositivePipe } from './make-positive.pipe';

@NgModule({
  imports: [],
  declarations: [DateAgoPipe, ToFixedPipe,FilterByPipe,FormArrayFilterPipe,SortPipe, MakePositivePipe],
  exports: [DateAgoPipe, ToFixedPipe,FilterByPipe,FormArrayFilterPipe,SortPipe, MakePositivePipe]
})
export class PipesModule { }
