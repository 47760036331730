<!-- app-custom-toaster.component.html -->
<div *ngIf="data">
    <div *ngIf="!error" class="p-4"
        style="border-radius: 15px; background-color: rgb(213, 249, 238); width: fit-content;">
        <div class="flex  content">
            <svg class="m-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
                    fill="#2BB673" stroke="#2BB673" stroke-width="2" stroke-linejoin="round" />
                <path d="M8 12L11 15L17 9" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <p class="mt-2 ms-2 me-2 font-medium">{{toastMessage}}</p>
            <svg (click)="closeToaster()" style="cursor: pointer;" class="m-2" width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4L20 20" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 20L20 4" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
    <div *ngIf="error" class="p-4" style="border-radius: 15px; background-color: #FFF2F9; width: fit-content;">
        <div class="flex  content">
            <svg class="m-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
                    fill="#F03E3E" stroke="#F03E3E" stroke-width="2" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
                    fill="white" />
                <path d="M12 6V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p class="mt-2 ms-2 me-2">{{toastMessage}}</p>
            <svg style="cursor: pointer;" (click)="closeToaster()" class="m-2" width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4L20 20" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 20L20 4" stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
</div>