<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog custom-alert" role="document">
        <div class="modal-content" style="width: 550px;">
            <div *ngIf="message?.type == 'confirm'" class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p style="font-weight: bold; font-size: 24px;  margin-top: 22px; margin-bottom: 30px;" class="text-center confirm-message">  Are you sure you want to <br>cancel your subscription?</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="confirm-button" style="  margin-top: -27px !important; margin-bottom: 22px !important;">
                            <a (click)="message.noFn()" class="mr-2.5">
                                <button style="height: 36px; margin-top: 22px; font-size:14px !important;font-weight:700 !important;"type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-success m-2 rounded-pill">No, I'm not sure</button>
                            </a>
                            <a (click)="openDialog()" (click)="message.yesFn()">
                                <button   style="height: 36px;width: 230px; margin-top: 22px; font-size:14px !important;font-weight:700 !important;"class="bg-danger text-white rounded-full w-2/5 h-8">Yes, cancel my renewal</button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
