<!-- dashboard body -->
<div>
<section>
<div class="dashboard-body" style="margin-top: 6rem;">
    <app-custom-toaster *ngIf="showToaster" (toasterClosed)="handleToasterClosed()"></app-custom-toaster>
    <div class="dashboard-text">
      <p class="text-gray-brand text-2xl font-semibold">
        Welcome, {{ user?.user_first_name }}
      </p>
    </div>
    <div class="grid grid-col-1 lg:grid-cols-3 mt-8 gap-3">
      <div *ngIf="!isGuideCompleted" class="col-span-3">
        <div class="bg-white rounded-xl w-full">
          <div class="flex py-7 px-5 justify-between align-items-center w-full">
            <p class="montserrat font-medium text-gray-brand text-18pt">
              Getting Started
            </p>
            <div class="flex justify-content-end align-items-center w-1/2">
                <p-progressBar class="w-75 rounded-xl" [value]="progressPercentage" [showValue]="false">
                </p-progressBar>
              <!-- <mat-progress-bar class="w-50 py-2 rounded-xl" mode="determinate" [value]="progressPercentage">
              </mat-progress-bar> -->
              <p class="w-48 text-center font-medium text-black text-16pt">
                <span *ngIf="completedSteps != 0">{{ completedSteps }} of {{ totalSteps }}</span>
                <span *ngIf="completedSteps == 0">0 of {{totalSteps}}</span>
              </p>
              <div class="dashboard tooltip-container showhim">
              <button (click)="openStartGuideDialog()" *ngIf="!isStepsCompleted" class="rounded-full custom-button-cancel py-2 w-36 h-10">
              <span class="text-white" style="font-weight: 400;">View Guide</span>
            </button>
            <div *ngIf="isFirstTimeSetup" class="hidden showme float text-left">
                <div style="overflow: hidden;">
                  <h1 class="p-1 font-semibold" style="font-size: 16px;">Getting Started</h1>
                  <p class="p-1" style="font-size: 14px !important;">Not sure where to get started? We put together this quick guide to help you setup your account and begin tracking your costs, sooner than later.</p>
                  <div class="flex justify-content-around align-items-center">
                    <span (click)="isFirstTimeSetup = false" class="text-brand-green cursor-pointer font-semibold">Ok, got it</span>
                    <button (click)="isFirstTimeSetup = false;openStartGuideDialog()"
                    type="button"
                  class="rounded-full custom-button-cancel py-2"
                >
                  View
                </button>
                  </div>
                </div>
              </div>
              <div *ngIf="isFirstTimeSetup" class="hidden showme float-arrow">
                <div class="triangle-down"></div>
              </div>
        </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div>
            <div class="bg-white py-2 px-1 rounded-t-2xl w-full grid grid-cols border-b">
                <div>
                    <div class="flex justify-content-between px-4 py-2">
                        <p class="font-semibold">Open Orders <span *ngIf="isMODataAvailable" class="ml-2" style="color: #868E96;">{{dashboardManufacturingOrderList[0].Total_Count}}</span></p>
                        <p routerLink="/fulfillment" class="font-semibold text-brand-green cursor-pointer">View All</p>
                    </div>
                </div>
            </div>
            <table class="w-full table-size">
                <tbody *ngIf="isMODataAvailable">
                 <tr *ngFor="let item of dashboardManufacturingOrderList; let i = index"
                 class="w-full bg-white border-b text-xs text-center montserrat h-12">
                 <td routerLink="/fulfillment/fulfilmentorderdetail/{{item.id}}"  [queryParams]="{dashboard: true}"
                     class="py-2 px-2 flex">
                     <div class="mx-3">
                     <img src="{{item.customer_image == '' || item.customer_image == null ? '/assets/icons/Customer_icon_img.png' : item.customer_image }}" alt="img" class="bg-gray-300"
                         style="height: 48px; width: 64px; border-radius: 5px;">
                     </div>
                         <div class="text-left">
                         <p class="mb-1">{{item.customer_first_name + ' ' +
                            item.customer_last_name}}</p>
                        <p class="font-semibold"> {{item.order_number}}</p>
                     </div>        
                 </td>
                 <td routerLink="/fulfillment/fulfilmentorderdetail/{{item.id}}"  [queryParams]="{dashboard: true}" class="px-4">
                    <div class="w-50">
                    <p class="mb-1 text-right">Profit</p>
                    <p [ngStyle]="{'color' : item.total_profit > 0 ? '#2BB673' : 'red'}" class="font-semibold text-right">{{ item.total_profit <
                        0 ? (item.total_profit | currency:'USD':'symbol-narrow':'1.2-2') : ('+' +
                        (item.total_profit | currency:'USD':'symbol-narrow':'1.2-2')) }}</p>
                    </div>
                </td>
                 <td routerLink="/fulfillment/fulfilmentorderdetail/{{item.id}}"  [queryParams]="{dashboard: true}" class="pl-4">
                     <p class="mb-1 text-left">Delivery Date</p>
                     <p class="font-semibold text-black text-left" [class.text-danger]="checkDate(item.delivery_date) == 'Yesterday'">{{checkDate(item.delivery_date)}}</p>
                 </td>
               
                 <td routerLink="/fulfillment/fulfilmentorderdetail/{{item.id}}"  [queryParams]="{dashboard: true}" class="pl-4">
                    <div class="relative" style="display: flex; justify-content: center; align-items: center;">
                        <div class="align-inline2 bg-color resources_color"
                            style="height: 30px !important; padding-right: 10px; width: fit-content;"
                            [ngClass]="{
                                'in_stock': getResourceStatus(item) === 'In stock',
                                'need_stock': getResourceStatus(item) === 'Need stock',
                                'committed': getResourceStatus(item) === 'Committed',
                                'expected_Date': getResourceStatus(item) !== 'Committed' && getResourceStatus(item) !== 'In stock' && getResourceStatus(item) !== 'Need stock'
                              }">
                            <p style="padding-top: 6px;">
                                {{getResourceStatus(item)}}
                            </p>
                        </div>
                    </div>
                </td>
                <td class="pr-4">
                    <div class="d-flex justify-content-end align-items-center">
                    <div class="relative"
                        style="width: max-content; cursor: pointer;">
                        <div class="flex statusHovrablestyle"
                            (click)="toggleStatusDropDown($event, i+4); getResourceCount(item)"
                            [ngStyle]="{'background-color': item.production_status == 0 ? '#DEE2E6' : item.production_status == 1 ? '#D5EEF9' : '#E6D5FD'}"
                            style="justify-content: space-between; padding: 7px; font-weight: 500; color: black; border-radius: 8px;">
                            <p *ngIf="item.production_status == 0" class="ps-2 pt-1">
                                Not started
                            </p>
                            <p *ngIf="item.production_status == 1" class="ps-2 pt-1"
                                style="color: #028AFC;">
                                In progress
                            </p>
                            <p *ngIf="item.production_status == 2" class="ps-2 pt-1"
                                style="color: #984DFA;">
                                Ready to ship
                            </p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    [ngStyle]="{'stroke' : item.production_status == 1 ? '#028AFC' : item.production_status == 2 ? '#984DFA' : 'black'}"
                                    d="M18 9L12 15L6 9" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div *ngIf="isStatusDropDownVisible[i+4]"
                            class="absolute right-0 py-2 w-30 bg-white rounded-md shadow-xl text-left z-20"
                            style="border-radius: 10px; width: max-content;">
                            <a *ngIf="item.production_status == 0"
                                class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                <div class="flex">
                                    <span>Not started</span>
                                </div>
                            </a>
                            <a *ngIf="item.production_status != 1 && item.production_status != 2"
                                (click)="openMOStatusModal(1)"
                                class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                <div class="flex">
                                    <span>In progress</span>
                                </div>
                            </a>
                            <a *ngIf="item.production_status != 2" (click)="openMOStatusModal(2)"
                                class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                <div class="flex">
                                    <span>Ready to ship</span>
                                </div>
                            </a>
                            <a (click)="onMOStatusChange(orderId, 3)"
                                class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                <div class="flex">
                                    <span>Shipped</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                </td>
             </tr>
             </tbody> 
         </table>
            <div *ngIf="!isMODataAvailable">
                <div style="border-bottom-left-radius: 20px;">
                    <div class="text-center bg-white w-full py-5 section-border">
                        <div class="pt-5" style="padding-left: 47%;">
                            <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse cx="55.5981" cy="34.0763" rx="55.5981" ry="34.0763" transform="matrix(-0.745734 -0.666244 -0.666244 0.745734 112.001 59)" fill="#D5F9EE"/>
                                <rect x="34.0002" y="49.0547" width="40" height="24.9434" stroke="#1B8E56" stroke-width="2"/>
                                <path d="M48.4531 57.1645V48.8438H59.5475V57.1645L54.0003 54.7871L48.4531 57.1645Z" fill="#7DDDB5" stroke="#1B8E56" stroke-width="2"/>
                                <rect x="34.0002" y="27.6641" width="31" height="21" stroke="#1B8E56" stroke-width="2"/>
                                <path d="M44.0002 36V28H55.0002V36L49.5002 33.7143L44.0002 36Z" fill="#7DDDB5" stroke="#1B8E56" stroke-width="2"/>
                                <rect x="34.0002" y="11.5" width="24" height="16" stroke="#1B8E56" stroke-width="2"/>
                                <path d="M42.0002 17.5V11.5H50.0002V17.5L46.0002 15.7857L42.0002 17.5Z" fill="#7DDDB5" stroke="#1B8E56" stroke-width="2"/>
                                <circle cx="28" cy="80" r="14" fill="#7DDDB5"/>
                                <circle cx="29" cy="79" r="12" stroke="#1B8E56" stroke-width="2"/>
                                <circle cx="29" cy="79" r="5" fill="#D5F9EE" stroke="#1B8E56" stroke-width="2"/>
                                <path d="M87.5 80C88.0523 80 88.5 79.5523 88.5 79C88.5 78.4477 88.0523 78 87.5 78V80ZM41 80H87.5V78H41V80Z" fill="#1B8E56"/>
                                <path d="M29 66.5V13C29 13 29 8 24 7.5" stroke="#1B8E56" stroke-width="2"/>
                                <rect x="4" y="11" width="10" height="20" rx="4.5" transform="rotate(-90 4 11)" fill="#7DDDB5"/>
                                <rect x="8" y="11" width="7" height="16" rx="3.5" transform="rotate(-90 8 11)" stroke="#1B8E56" stroke-width="2"/>
                                </svg>
                                
                        </div>
                        <h3 class="pb-4 pt-3">You don’t have any orders to fulfill</h3>
                        <p class="text-gray-brand pb-5">Once you have a new order to fulfill, add it to your manufacturing queue.
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white py-2 px-1 rounded-b-2xl w-full grid grid-cols border-t">
                <div>
                    <!-- <div class="flex px-4 py-2 justify-content-end">
                        <div [routerLink]="['/fulfillment/createfulfilmentorderdetail', this.nextMONumber]" [queryParams]="{dashboard: true}" style="cursor: pointer; width: fit-content;" class="edit-button ps-3 h-10 pt-2 flex">
                            <div class="">
                                <i class="text-white fa fa-plus"></i>
                            </div>
                            <div class="text-white ps-2 pe-3">New Order</div>
                        </div> -->
                    <div class="flex px-4 py-2" [class.justify-content-between]="subscriptionInfo && subscriptionInfo.stripe_product_id < 3" [class.justify-content-end]="subscriptionInfo == null || subscriptionInfo?.stripe_product_id == 3">
                        <div *ngIf="subscriptionInfo && subscriptionInfo?.stripe_product_id < 3">
                            <span *ngIf="subscriptionInfo?.stripe_product_id == 1 && orderLimitPerMonth - orderLimitUsed > 0">{{orderLimitPerMonth - orderLimitUsed}} orders remaining <span (click)="CreateCustomerPortalSession()" class="ml-2 cursor-pointer" style="color: #028AFC;"><b>Upgrade for more</b></span> </span>
                            <span *ngIf="subscriptionInfo?.stripe_product_id == 2 && orderLimitPerMonth - orderLimitUsed > 0 && orderLimitPerMonth - orderLimitUsed <= 25">{{orderLimitPerMonth - orderLimitUsed}} orders remaining <span (click)="CreateCustomerPortalSession()" class="ml-2 cursor-pointer" style="color: #028AFC;"><b>Upgrade for more</b></span> </span>
                            <span *ngIf="orderLimitPerMonth - orderLimitUsed <= 0">All {{orderLimitPerMonth}} orders used </span>
                        </div>
                        <div *ngIf="subscriptionInfo && subscriptionInfo?.stripe_product_id < 3; else elseBlock">
                        <div *ngIf="orderLimitUsed < orderLimitPerMonth"  [routerLink]="['/fulfillment/createfulfilmentorderdetail', this.nextMONumber]" [queryParams]="{dashboard: true}" style="cursor: pointer; width: fit-content;" class="edit-button ps-3 h-10 pt-2 flex">
                        <div class="">
                            <i class="text-white fa fa-plus"></i>
                        </div>
                        <div class="text-white ps-2 pe-3">New Order</div>
                    </div>
                    <div *ngIf="orderLimitUsed >= orderLimitPerMonth"  (click)="openBlockModal()" style="cursor: pointer; width: fit-content;" class="edit-button ps-3 h-10 pt-2 flex">
                        <div class="">
                            <i class="text-white fa fa-plus"></i>
                        </div>
                        <div class="text-white ps-2 pe-3">New Order</div>
                    </div>
                    </div>
                    <ng-template #elseBlock>
                        <div [routerLink]="['/fulfillment/createfulfilmentorderdetail', this.nextMONumber]" [queryParams]="{dashboard: true}" style="cursor: pointer; width: fit-content;" class="edit-button ps-3 h-10 pt-2 flex">
                            <div class="">
                                <i class="text-white fa fa-plus"></i>
                            </div>
                            <div class="text-white ps-2 pe-3">New Order</div>
                        </div>
                    </ng-template>
                    </div>
                </div>
            </div>
        </div>
      </div>
 <!-- Open Purchase Orders Section -->
 <div class="col-span-3">
    <div class="grid grid-col-1 lg:grid-cols-2 gap-3">
      <div class="col-span-1">
        <div>
            <div class="bg-white py-2 px-1 rounded-t-2xl w-full grid grid-cols border-b">
                <div>
                    <div class="flex justify-content-between px-4 py-2">
                        <p class="font-semibold">Open Purchase Orders <span *ngIf="isPODataAvailable" class="ml-2" style="color: #868E96;">{{dashboardPurchaseOrderList.length}}</span></p>
                        <p routerLink="/inventory/purchaseorderslisting" class="text-brand-green cursor-pointer font-semibold">View All</p>
                    </div>
                </div>
            </div>
             <table class="w-full table-size">
                   <tbody *ngIf="isPODataAvailable">
                    <tr *ngFor="let item of dashboardPurchaseOrderList; let i = index"
                    class="w-full bg-white border-b text-xs text-center montserrat h-12">
                    <td routerLink="/inventory/purchaseorderslisting/purchaseorders/{{item.id}}"
                        [queryParams]="{dashboard:true}"    
                        class="py-2 px-2 flex">
                        <div class="mx-3">
                        <img src="{{item.image_url == null || item.image_url == '' ? '/assets/icons/Customer_icon_img.png' : item.image_url}}" alt="img" class="bg-gray-300"
                            style="height: 48px; width: 64px; border-radius: 5px;">
                        </div>
                            <div class="text-left">
                            <p class="mb-1">{{item.company_name}}</p>
                           <p class="font-semibold"> {{item.PO_number}}</p>
                        </div>        
                    </td>
                    <td routerLink="/inventory/purchaseorderslisting/purchaseorders/{{item.id}}" [queryParams]="{dashboard:true}">
                        <p class="mb-1 text-left">Expected</p>
                        <p class="font-semibold text-black text-left">{{ checkDate(item.expected_arrival_date) }}</p>
                    </td>
                  
                    <td routerLink="/inventory/purchaseorderslisting/purchaseorders/{{item.id}}"
                    [queryParams]="{fromdashboard:true}">
                        <div class="relative p30 w-100 d-flex justify-content-end">
                            <div class="flex statusHovrablestyle" (click)="toggleStatusDropDown($event, i)"
                                    [ngStyle]="{'background-color': item.status == 3 ? '#FFF9DE' : '#DEE2E6'}"
                                    style="justify-content: space-between; padding: 7px; font-weight: 500; color: black; border-radius: 8px;">
                                    <p *ngIf="item.status == 1" class="ps-2 pt-1">
                                        Not received
                                    </p>
                                    <p *ngIf="item.status == 3" class="ps-2 pt-1" style="color: #FF9B43;">
                                        Partially received
                                    </p>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path [ngStyle]="{'stroke' : item.status == 3 ? '#FF9B43' : 'black'}"
                                            d="M18 9L12 15L6 9" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>
                            <div *ngIf="isStatusDropDownVisible[i]" 
                                    class="absolute top-10 right-0  w-30 bg-white rounded-md shadow-xl text-left z-20"
                                    style="border-radius: 10px; margin-right: 27px;width: 125px;">
                                    <!-- <a *ngIf="item.status != 3" 
                                        class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                        <div class="flex">
                                            <span>Not received</span>
                                        </div>
                                    </a> -->
                                    <a (click)="onPOStatusChange(item, 2)" routerLink="/inventory/purchaseorderslisting/purchaseorders/{{item.id}}"  [queryParams]="{fromdashboard:true}"
                                        class="block px-4 py-2 mt-1 mb-1 text-sm font-semibold text-gray-600 montserrat rounded-lg dropdown-text">
                                        <div class="flex">
                                            <span>Received</span>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </td>
                </tr>
                </tbody> 
            </table>
            <div *ngIf="!isPODataAvailable">
                <div style="border-bottom-left-radius: 20px;">
                    <div class="text-center bg-white w-full py-5 section-border">
                        <div class="pt-5" style="padding-left: 47%;">
                            <svg width="96" height="96" viewBox="0 0 96 96" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 24C0 10.7452 10.7452 0 24 0H72C85.2548 0 96 10.7452 96 24V72C96 85.2548 85.2548 96 72 96H12C5.37258 96 0 90.6274 0 84V24Z"
                                    fill="#D5F9EE" />
                                <path
                                    d="M12.5493 95H68.0704C72.4887 95 76.0704 91.4183 76.0704 87V28.55C76.0704 26.3637 75.1757 24.2727 73.5942 22.7631L65.6848 15.2132C64.1966 13.7926 62.2183 13 60.161 13H12.5493V95Z"
                                    stroke="#1B8E56" stroke-width="2" />
                                <path
                                    d="M62.7888 25.7049V13.5781C67.0235 16.8504 75.3775 23.857 74.9155 25.7049H62.7888Z"
                                    fill="#7DDDB5" stroke="#1B8E56" stroke-width="2" stroke-linejoin="round" />
                                <path
                                    d="M58.7465 82.2969H1V85.7617C1 90.8645 5.13664 95.0011 10.2394 95.0011H67.9859C62.8831 95.0011 58.7465 90.8645 58.7465 85.7617V82.2969Z"
                                    fill="#7DDDB5" stroke="#1B8E56" stroke-width="2" />
                                <rect x="19.3242" y="39.4102" width="7.23944" height="7.23944" rx="1"
                                    fill="#7DDDB5" stroke="#1B8E56" stroke-width="2" />
                                <path d="M33.3383 40.7188H62.2116" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <path d="M33.3383 46.4961H48.3524" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <rect x="19.3242" y="26" width="7.23944" height="7.23944" rx="1" fill="#7DDDB5"
                                    stroke="#1B8E56" stroke-width="2" />
                                <path d="M33 27H53" stroke="#1B8E56" stroke-width="2" stroke-linecap="round" />
                                <path d="M33.3383 33.0859H48.3524" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <rect x="19.3237" y="53.2695" width="7.23944" height="7.23944" rx="1"
                                    fill="#7DDDB5" stroke="#1B8E56" stroke-width="2" />
                                <path d="M32.9995 55H52.9995" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <path d="M33.3378 60.3555H48.3519" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <rect x="19.3237" y="67.125" width="7.23944" height="7.23944" rx="1"
                                    fill="#7DDDB5" stroke="#1B8E56" stroke-width="2" />
                                <path d="M33.3378 68.4336H62.211" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                                <path d="M33.3378 74.2109H48.3519" stroke="#1B8E56" stroke-width="2"
                                    stroke-linecap="round" />
                            </svg>
                        </div>
                        <h3 class="pb-4 pt-3">You don’t have any open purchase orders</h3>
                        <p class="text-gray-brand pb-5">Need to restock inventory? Create a purchase order for easy
                            tracking!
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white py-2 px-1 rounded-b-2xl w-full grid grid-cols border-t">
                <div>
                    <div class="flex justify-content-end px-4 py-2">
                        <div style="cursor: pointer; width: fit-content;" class="edit-button ps-3 h-10 pt-2 flex"
                        [routerLink]="['/inventory/purchaseorderslisting/purchaseorders',this.poNumberByStock, true, true]"
                        [queryParams]="{dashboard: true}">
                        <div class="">
                            <i class="text-white fa fa-plus"></i>
                        </div>
                        <div class="text-white ps-2 pe-3">New Purchase Order</div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!-- Low Stock Section-->
      <div class="col-span-1">
        <div>
            <div class="bg-white py-2 px-1 rounded-t-2xl w-full grid grid-cols border-b">
                <div>
                    <div class="flex justify-content-between px-4 py-2">
                        <p class="font-semibold">Low Stock</p>
                        <p routerLink="/inventory/stocklisting" class="text-brand-green cursor-pointer font-semibold">View All</p>
                    </div>
                </div>
            </div>
            <table class="w-full table-size">
                 <tbody *ngIf="isStockDataAvailable">
                    <tr *ngFor="let item of lowStockList; let i = index"
                    class="w-full bg-white border-b text-xs text-center montserrat h-12">
                    <td routerLink="/inventory/{{item.ItemType == 'Material' ? 'materiallist/material' : 'ingredientlist/ingredient'}}/{{item.id}}"
                    [queryParams]="{dashboard: true}"
                        class="py-2 px-2 flex">
                        <div class="mx-3">
                        <img src="{{item.image_path == '' || item.image_path == null ? '/assets/icons/Customer_icon_img.png' : item.image_path}}" alt="img" class="bg-gray-300"
                            style="height: 48px; width: 64px; border-radius: 5px;">
                        </div>
                            <div class="text-left">
                            <p class="mb-1">{{item.company_name}}</p>
                           <p class="font-semibold"> {{item.item_name}}</p>
                        </div>        
                    </td>
                    <td routerLink="/inventory/{{item.ItemType == 'Material' ? 'materiallist/material' : 'ingredientlist/ingredient'}}/{{item.id}}"
                    [queryParams]="{dashboard: true}" class="">
                        <p class="mb-1 text-left">Units in Stock</p>
                        <p class="font-semibold text-black text-left">{{ item.total_units | number : '1.2-2' }}</p>
                    </td>
                  
                    <td>
                        <div class="relative p30 flex justify-content-end">
                            <button (click)="createPoOrder(item.id)" class="flex">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.6798 6.25L19.3048 15.75H6.69519L4.32019 6.25H21.6798Z"
                                        stroke="#2BB673" stroke-width="0.5" />
                                    <path d="M1.5 3H3.25L4 6M4 6L6.5 16H19.5L22 6H4Z" stroke="#2BB673"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path
                                        d="M6.5 21C7.32843 21 8 20.3284 8 19.5C8 18.6716 7.32843 18 6.5 18C5.67157 18 5 18.6716 5 19.5C5 20.3284 5.67157 21 6.5 21Z"
                                        stroke="#2BB673" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M19.5 21C20.3284 21 21 20.3284 21 19.5C21 18.6716 20.3284 18 19.5 18C18.6716 18 18 18.6716 18 19.5C18 20.3284 18.6716 21 19.5 21Z"
                                        stroke="#2BB673" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M11 11H15" stroke="#2BB673" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13 13V9" stroke="#2BB673" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div style="color:#2BB673;" class="pt-1 ps-1 font-semibold">Buy</div>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div *ngIf="isStockDataAvailable" class="bg-white py-2 px-1 rounded-b-2xl w-full grid grid-cols">
              <div>
                  
              </div>
          </div>
            <div *ngIf="!isStockDataAvailable">
                <div style="border-bottom-left-radius: 20px;">
                    <div class="rounded-b-2xl text-center bg-white w-full py-5 section-border">
                        <div class="pt-5" style="padding-left: 47%;">
                            <svg width="96" height="96" viewBox="0 0 96 96" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_6278_447409)">
                                    <path
                                        d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
                                        fill="#D5F9EE" />
                                    <path
                                        d="M78.24 36.4805H33.6C29.8887 36.4805 26.88 39.4891 26.88 43.2005V89.2805C26.88 92.9918 29.8887 96.0005 33.6 96.0005H78.24C81.9514 96.0005 84.96 92.9918 84.96 89.2805V43.2005C84.96 39.4891 81.9514 36.4805 78.24 36.4805Z"
                                        fill="#7DDDB5" />
                                    <path
                                        d="M73.44 32.1602H29.28C25.3036 32.1602 22.08 35.3837 22.08 39.3602V84.0002C22.08 87.9766 25.3036 91.2002 29.28 91.2002H73.44C77.4165 91.2002 80.64 87.9766 80.64 84.0002V39.3602C80.64 35.3837 77.4165 32.1602 73.44 32.1602Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path
                                        d="M73.92 16.3203H28.8C27.7396 16.3203 26.88 17.1799 26.88 18.2403V25.4403C26.88 26.5007 27.7396 27.3603 28.8 27.3603H73.92C74.9804 27.3603 75.84 26.5007 75.84 25.4403V18.2403C75.84 17.1799 74.9804 16.3203 73.92 16.3203Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path d="M72 27.3594H30.72V32.1594H72V27.3594Z" stroke="#1B8E56"
                                        stroke-width="2" />
                                    <path
                                        d="M39.84 70.0786C46.2023 70.0786 51.36 64.8135 51.36 58.3186C51.36 51.8237 46.2023 46.5586 39.84 46.5586C33.4777 46.5586 28.32 51.8237 28.32 58.3186C28.32 64.8135 33.4777 70.0786 39.84 70.0786Z"
                                        fill="#2BB673" />
                                    <path
                                        d="M39.84 69.1195C45.6722 69.1195 50.4 64.2842 50.4 58.3195C50.4 52.3549 45.6722 47.5195 39.84 47.5195C34.0079 47.5195 29.28 52.3549 29.28 58.3195C29.28 64.2842 34.0079 69.1195 39.84 69.1195Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path
                                        d="M63.12 70.0786C69.6149 70.0786 74.88 64.8135 74.88 58.3186C74.88 51.8237 69.6149 46.5586 63.12 46.5586C56.6251 46.5586 51.36 51.8237 51.36 58.3186C51.36 64.8135 56.6251 70.0786 63.12 70.0786Z"
                                        fill="#2BB673" />
                                    <path
                                        d="M63.12 69.1195C69.0847 69.1195 73.92 64.2842 73.92 58.3195C73.92 52.3549 69.0847 47.5195 63.12 47.5195C57.1553 47.5195 52.32 52.3549 52.32 58.3195C52.32 64.2842 57.1553 69.1195 63.12 69.1195Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path
                                        d="M51.36 75.8369C57.7223 75.8369 62.88 70.6792 62.88 64.3169C62.88 57.9546 57.7223 52.7969 51.36 52.7969C44.9977 52.7969 39.84 57.9546 39.84 64.3169C39.84 70.6792 44.9977 75.8369 51.36 75.8369Z"
                                        fill="#2BB673" />
                                    <path
                                        d="M51.36 74.8778C57.1922 74.8778 61.92 70.1499 61.92 64.3178C61.92 58.4857 57.1922 53.7578 51.36 53.7578C45.5279 53.7578 40.8 58.4857 40.8 64.3178C40.8 70.1499 45.5279 74.8778 51.36 74.8778Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path
                                        d="M39.84 85.4384C46.2023 85.4384 51.36 80.2808 51.36 73.9184C51.36 67.5561 46.2023 62.3984 39.84 62.3984C33.4777 62.3984 28.32 67.5561 28.32 73.9184C28.32 80.2808 33.4777 85.4384 39.84 85.4384Z"
                                        fill="#2BB673" />
                                    <path
                                        d="M39.84 84.4794C45.6722 84.4794 50.4 79.7515 50.4 73.9194C50.4 68.0872 45.6722 63.3594 39.84 63.3594C34.0079 63.3594 29.28 68.0872 29.28 73.9194C29.28 79.7515 34.0079 84.4794 39.84 84.4794Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                    <path
                                        d="M63.12 85.4384C69.6149 85.4384 74.88 80.2808 74.88 73.9184C74.88 67.5561 69.6149 62.3984 63.12 62.3984C56.6251 62.3984 51.36 67.5561 51.36 73.9184C51.36 80.2808 56.6251 85.4384 63.12 85.4384Z"
                                        fill="#2BB673" />
                                    <path
                                        d="M63.12 84.4794C69.0847 84.4794 73.92 79.7515 73.92 73.9194C73.92 68.0872 69.0847 63.3594 63.12 63.3594C57.1553 63.3594 52.32 68.0872 52.32 73.9194C52.32 79.7515 57.1553 84.4794 63.12 84.4794Z"
                                        stroke="#1B8E56" stroke-width="2" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6278_447409">
                                        <rect width="96" height="96" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <h3 class="pb-4 pt-3">You haven't added any resources</h3>
                        <p class="text-gray-brand pb-5">You don't have any resources to track stock
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
    </div>
  </div>


  <div class="modal" tabindex="-1" style="z-index: 10000;" role="dialog" [style.display]="isMOStatusModalOpen ? 'block' : 'none'">
    <div class="modal-dialog custom-alert" role="document">
        <div class="modal-content" style="width: 170%;">
            <div class="modal-body">
                <div class="row justify-content-end text-right ">
                    <div style="padding: 0px; width: 40px;">
                        <svg (click)="closeMOStatusModal()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="4" fill="#F1F3F5"/>
                            <path d="M8 8L24 24" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 24L24 8" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <p style="font-weight: bold; font-size: 24px;  margin-top: 22px; margin-bottom: 30px;"
                            class=" confirm-message">{{this.modalHeading}}</p>
                        <p style="font-size: 16px;" class="mb-2"><span [innerHtml]="modalMessage"></span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                        <p class="confirm-button"
                            style="margin-top: -27px !important; margin-bottom: 22px !important;">
                            <ng-container *ngIf="!isBlockStatus">
                            <a (click)="closeMOStatusModal()" class="mr-2.5">
                                <button
                                    style="height: 36px; width: 130px; margin-top: 22px; font-size:14px !important; font-weight:700 !important; color: #2BB673; border-color: #2BB673;"
                                    type="button" data-bs-dismiss="modal" aria-label="Close"
                                    class="btn btn-outline-secondary m-2 rounded-pill">Cancel</button>
                            </a>
                            <a (click)="onMOStatusChange(this.orderId, this.orderStatus)">
                                <button
                                    style="height: 36px; background-color: #2BB673; width: 130px; margin-top: 22px; font-size:14px !important; font-weight:700 !important;"
                                    class="text-white rounded-full w-2/5 h-8">Yes, I'm sure</button>
                            </a>
                            </ng-container>
                            <ng-container *ngIf="isBlockStatus">
                                <a routerLink="/inventory/stocklisting" class="mr-2.5">
                                    <button
                                        style="height: 36px; width: 200px; margin-top: 22px; font-size:14px !important; font-weight:700 !important; color: #2BB673; border-color: #2BB673;"
                                        type="button" data-bs-dismiss="modal" aria-label="Close"
                                        class="btn btn-outline-secondary m-2 rounded-pill">View Inventory</button>
                                </a>
                                <a (click)="closeMOStatusModal()">
                                    <button
                                        style="height: 36px; background-color: #2BB673; width: 130px; margin-top: 22px; font-size:14px !important; font-weight:700 !important;"
                                        class="text-white rounded-full w-2/5 h-8" data-bs-dismiss="modal" aria-label="Close">Ok</button>
                                </a>
                                </ng-container>

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</div>