import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-order-block-modal',
  templateUrl: './new-order-block-modal.component.html',
  styleUrls: ['./new-order-block-modal.component.css']
})
export class NewOrderBlockModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ElementRef
  modalContent:String = "You’ve used all <number_of_orders> of your manufacturing orders for the month. Your monthly limit will replenish once the next month begins. "
  planId: number;
  isDataLoaded: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private dialogRef: MatDialogRef<NewOrderBlockModalComponent>) { }

  ngOnInit(): void {
    this.planId = this.data.stripe_product_id;
    if(this.planId === 1){
      this.modalContent += 'Consider upgrading to unlock access to a higher limit.'
      this.modalContent = this.modalContent.replace('<number_of_orders>', '30');
      this.isDataLoaded = true;
    }
    else if(this.planId === 2){
      this.modalContent += 'Upgrade to unlock unlimited manufacturing orders.'
      this.modalContent = this.modalContent.replace('<number_of_orders>', '100');
      this.isDataLoaded = true;
    }
  }
  ngAfterViewInit():void{
    this.modal.nativeElement.scrollTop = 0
  }

  onUpgrade(){
    this.dialogRef.close('ok');
  }
  onClose(){
    this.dialogRef.close()
  }
}
