import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomToasterComponent } from './custom-toaster.component';


@NgModule({
  declarations: [CustomToasterComponent],
  imports: [
    CommonModule
  ],
  exports:[CustomToasterComponent]
})
export class ToasterModule { }
