import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

  constructor(private http: HttpClient) { }

  public requestDataFromMultipleSources(productCountList): Observable<any[]> {
    
    let recipeData = {
      typeID: 1,
      productCountList: productCountList
    }
    let responseRecipeDetailsList = this.http.post<any>(`${environment.apiUrl}/Fullfillment/Fetch_recipe_details_by_productID`, recipeData);

    let ingredientData = {
      typeID: 2,
      productCountList: productCountList
    }
    let responseIngredientsDetailsList = this.http.post<any>(`${environment.apiUrl}/Fullfillment/Fetch_ingredients_details_by_productID`, ingredientData);

    let itemData = {
      typeID: 3,
      productCountList: productCountList
    }
    let responseItemDetailsList = this.http.post<any>(`${environment.apiUrl}/Fullfillment/Fetch_items_details_by_productID`, itemData);

    let materialData = {
      typeID: 4,
      productCountList: productCountList
    }
    let responseMaterialsDetailsList = this.http.post<any>(`${environment.apiUrl}/Fullfillment/Fetch_materials_details_by_productID`, materialData);

    return forkJoin([
      responseRecipeDetailsList,
      responseIngredientsDetailsList,
      responseItemDetailsList,
      responseMaterialsDetailsList
    ]);
  }

  manufacturingOrderInsertUpdate(model: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/Fullfillment/manufacturingOrder_Insert_Update`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  updateManufacturingOrderStatus(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/Fullfillment/updateManufacturingOrderStatus`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  getmanufacturingOrder(userid: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/Fullfillment/Fetch_manufacturingOrder?user_id=${userid}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  manufacturingOrderById(id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/Fullfillment/manufacturingOrderById?id=${id}`)
      .pipe(
        map((x) => {

          return x;
        })
      );
  }
  fulfillmentRequirementDetails(pId: number, packId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/Fullfillment/fulfillmentRequirementDetails?productId=${pId}&packagingId=${packId}`)
      .pipe(
        map((x) => {

          return x;
        })
      );
  }
  removemanufacturingOrder(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Fullfillment/deleteManufacturingOrder`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  ResupplyIngredientInsertUpdate(model: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/Fullfillment/Resupply_ingredient_Insert_Update`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  ResupplyCostInsertUpdate(model: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/Fullfillment/Resupply_Cost_Insert_Update`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  ResupplyMaterialInsertUpdate(model: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/Fullfillment/Resupply_material_Insert_Update`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
}
