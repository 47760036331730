import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makePositive'
})
export class MakePositivePipe implements PipeTransform {

  transform(num: number, ...args: any): number {
    return Math.abs(num);;
  }

}
