<div appBsModal bsModal class="modal fade edit-school-modal show d-block post-view-large" id="create-post" tabindex="-1"
    aria-labelledby="create-post" aria-hidden="true" area-modal="true">
    <div class="modal-body">
        <!-- <div>
            <h1  class="congratulationPopup">Congratulations, you're all <br> set up!</h1>
        </div> -->
        <div>
            <h1 class="congratulationPopup">Your Suscription has been <br>updated!</h1>
        </div>
        <div class="containingButtons">
            <button (click)="showModal()" (click)="OnClickReload()" value="Reload Page" onClick="window.location.reload(true)" #closeUpgradeModal type="button" class="btn btn-outline-success m-2 rounded-pill" data-bs-dismiss="modal" aria-label="Close" onclick="refreshPage()">Done</button>
            <button (click)="gotoExternalDomain()" type="button" class="btn btn-success m-2 btn-lg rounded-pill" style="padding: 12.5px;">Give Feedback</button>
        </div>
    </div>
</div>