import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure:true
})
export class FilterByPipe implements PipeTransform {

  transform(items: any, keyName: any, searchKeyword: any): any {

    if (items != undefined) {
      if (keyName == "all") {
        let filterKeys = Object.keys(items[0]);
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(searchKeyword, 'gi').test(item[keyName]) || keyName == "";
          });
        });
      }
      if (keyName && Array.isArray(items)) {
        return items.filter(item => {
          return new RegExp(searchKeyword, 'gi').test(item[keyName]) || keyName == "";
        });
      }
    }
  }
}