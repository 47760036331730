<div class="modal-backdrop">
    <div #modal class="modal" tabindex="-1" style="display: block !important  ">
      <div class="modal-dialog custom-alert" role="document">
        <div class="modal-content" style="width: 130%;">
          <div class="modal-body">
            <div class="row justify-content-end text-right">
              <div (click)="onDoneClick()" style="padding: 0px; width: 40px;">
                  <svg  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="32" height="32" rx="4" fill="#F1F3F5"/>
                      <path d="M8 8L24 24" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 24L24 8" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <p style="font-weight: bold; font-size: 24px;  margin-top: 22px; margin-bottom: 30px;"
                      class=" confirm-message">Leave feedback and get a response immediately!</p>
                      <p style="font-size: 16px;" class="mb-2">Tell us about your experience or frustrations and we’ll respond within 1 business day. We are determined to create an experience that is valuable for your food business.</p>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
              <p class="confirm-button" style="margin-top: 10px !important; margin-bottom: 20px !important;">
                 
                      <button (click)="onDoneClick()"  style="height: 40px; color: #2bb673; border: 1px solid #2bb673; background-color: white; width: 140px; margin-top: 22px; font-size:14px !important; font-weight:500 !important;"
                          type="button" class="m-2 rounded-pill custom-button">All is good!</button>
                  
                  
                      <a target=”_blank” href="https://www.prophitapp.com/feedback"><button (click)="onDoneClick()"  style="height: 40px;color: white; background-color: #2bb673; width: 140px; margin-top: 22px; font-size:14px !important; font-weight:500 !important;"
                        class="text-white rounded-full w-2/5 h-8">Leave Feedback</button></a>
                  
              </p>  
          </div>
          </div>
          </div>
        </div>
      </div>
      </div>
      </div>
