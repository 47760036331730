import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'app-custom-toaster',
  templateUrl: './custom-toaster.component.html',
  styleUrls: ['./custom-toaster.component.css']
})
export class CustomToasterComponent implements OnInit, OnDestroy {
  showToaster: boolean;
  error: boolean;
  data : boolean;
  toastMessage: any;
  @Output() toasterClosed = new EventEmitter<void>();
  private subscription: Subscription;

  constructor(private toasterService: ToasterService) { }

  ngOnInit() {
    this.subscription = this.toasterService.data$.subscribe((data) => {
      if (data) {
        this.data = data
        this.toastMessage = data.message;
        this.error = data.type === "error";
      }
    });
  }

  closeToaster() {
    this.toasterClosed.emit();
  }

  @HostListener('window:beforeunload') async ngOnDestroy() {
    if (this.subscription) {
          this.subscription.unsubscribe();
        }
  }
  
  // ngOnDestroy() {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  //   console.log(this.subscription)
  // }
}
