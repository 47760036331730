import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (req.url.startsWith('assets')) {
      return next.handle(req);
    }

    let clonedReq = req.clone();

    // Set header for
    if (!req.url.includes('/api/Accounts/GetUserLoginList')) {
      clonedReq = clonedReq.clone({
        setHeaders: {
          Authorization: `Bearer ${this.localStorage.getAuthToken()}`,
        },
      });
    }
    

    return next.handle(clonedReq);
  }
}
