import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap, delay, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {

  private isStartGuideSource$ = new BehaviorSubject(false);
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) {
  }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  getStartGuideSource(){
    return this.isStartGuideSource$.asObservable();
  }
  setStartGuideSource(isStartGuide: boolean){
    this.isStartGuideSource$.next(isStartGuide);
  }
  
  getAllUnitList(unitType = 'Weight') {
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/GetUnitList?unitType=${unitType}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }  

  getFacilityLabourList(UserId: number) {
    
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/GetFacilityLabourList?user_id=${UserId}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getMerchantFeeList(UserId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/GetMerchantFeeList?user_id=${UserId}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  getStartGuideStepList(UserId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/GetStartGuideStepsList?user_id=${UserId}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

}