export const BILLING_CYCLE = [
  {
    id: "price_1LolifCMAVKJmd2C4NbDbRJY",
    label: "Monthly ($50 per month)",
    value: "monthly",
    amount: 50,
    cycle_amount: 50
  },
  {
    id: "price_1LolktCMAVKJmd2Cdo7etJfI",
    label: "Quarterly ($35 per month)",
    value: "quarterly",
    amount: 35,
    cycle_amount: 105
  },
  {
    id: "price_1LoliYCMAVKJmd2CO9rJLhUm",
    label: "Yearly ($30 per month)",
    value: "yearly",
    amount: 30,
    cycle_amount: 360
  },
];