<div #modal
class="modal fade edit-school-modal large_modal "
id="upgradeSubscription"
tabindex="-1"
aria-labelledby="upgradeSubscription"
aria-hidden="true"
data-backdrop="static"
  data-keyboard="false"
  >
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div>
        <div class="upgradeNowTopHeading">
          <h3 *ngIf="this.freeTrialdaysLeft > 0">
            Upgrade Now for 30% off<br />
            each billing cycle
          </h3>
        </div>
      </div>
      <!-- {{this.DaysLeft}} -->
      <div *ngIf="freeTrialdaysLeft <= 0 && (user[0].DaysLeft !== null && user[0].DaysLeft === 0)">
        <div class="upgradeNowTopHeading">
          <h3>We're happy you're back!</h3>
        </div>
      </div>
      <div class="modalBody">
        <form
          [formGroup]="upgradeSubscriptionForm"
          (ngSubmit)="onSubmitClick()"
          id="account_create_from"
        >
          <p class="label-text text-left mb-1" style="font-size: 20px">
            Choose your subscription cycle:
          </p>
          <div class="form-group mb-4 custom-dropdown">
            <div class="form-group mb-4 custom-dropdown">
              <p-dropdown
                [options]="billingCycleList"
                [(ngModel)]="selectedBillingCycle"
                formControlName="billingCycle"
                optionLabel="label"
                optionValue="value"
                placeholder="&nbsp;"
              >
                <ng-template let-item pTemplate="item">
                  <div
                    (click)="dropdownClicked($event)"
                    [innerHtml]="item.value.labelInnerText"
                  ></div>
                </ng-template>
                <ng-template pTemplate="selectedItem">
                  <div
                    class="billing-cycle-item-value"
                    *ngIf="selectedBillingCycle"
                  >
                    <div
                      innerHtml="{{
                        populateRichTextOption(selectedBillingCycle)
                      }}"
                    ></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <div
                class="text-left text-danger"
                *ngIf="f.billingCycle.touched && f.billingCycle.errors"
              >
                <div *ngIf="f.billingCycle.errors.required">
                  This is <strong>required</strong>
                </div>
              </div>
            </div>
            <div
              class="text-left text-danger"
              *ngIf="f.billingCycle.touched && f.billingCycle.errors"
            >
              <div *ngIf="f.billingCycle.errors.required">
                This is <strong>required</strong>
              </div>
            </div>
          </div>
          <p class="label-text text-left mb-1" style="font-size: 20px">
            Payment details:
          </p>
          <div class="style-font12 card-information">
            <div class="form-group mb-3">
              <div class="enabled">
                <input
                  *ngIf="!isInputClicked"
                  class="fon_size"
                  type="text"
                  name="cardName"
                  formControlName="cardName"
                  class="form-control"
                  id="cardName"
                  placeholder="Name on card"
                />
              </div>
              <!--disabled cardname -->
              <input
                *ngIf="isInputClicked"
                [attr.disabled]="isDisabled"
                class="fon_size"
                type="text"
                name="cardNameDisabled"
                formControlName="cardNameDisabled"
                class="form-control"
                id="cardNameDisabled"
                placeholder="Name on card"
              />
              <!-- disabled cardname -->
              <div
                class="text-left text-danger"
                *ngIf="
                  (f.cardName.touched && f.cardName.errors) ||
                  (f.cardName.errors && submitted && !isInputClicked)
                "
              >
                <div *ngIf="f.cardName.errors.required">
                  This is <strong>required</strong>
                </div>
              </div>
            </div>
            <div
              class="form-group mb-3 input-number"
              style="font-size: 20px; display: flex"
            >
              <div class="card-number" style="flex: 48%">
                <div class="enabled">
                  <input
                    style="
                      font-size: 20px !important;
                      font-family: 'Montserrat', sans-serif !important;
                    "
                    *ngIf="!isInputClicked"
                    class="fon_size"
                    type="text"
                    name="cardNumber"
                    formControlName="cardNumber"
                    class="form-control style=font-size:20px"
                    mask="0000 0000 0000 000||0000 0000 0000 0000"
                    id="cardNumber"
                    placeholder="Card number"
                  />
                </div>
                <!--disabled cardnumber -->
                <input
                  style="
                    font-size: 20px !important;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  *ngIf="isInputClicked"
                  [attr.disabled]="isDisabled"
                  class="fon_size"
                  type="text"
                  name="cardNumberWithoutMask"
                  formControlName="cardNumberWithoutMask"
                  class="form-control style=font-size:20px"
                  id="cardNumberWithoutMask"
                  placeholder="Card number"
                />
                <!-- disabled cardnumber -->
                <div
                  class="text-left text-danger"
                  *ngIf="
                    (f.cardNumber.touched && f.cardNumber.errors) ||
                    (f.cardNumber.errors && submitted && !isInputClicked)
                  "
                >
                  <div *ngIf="f.cardNumber.errors.required">
                    This is <strong>required</strong>
                  </div>
                  <div *ngIf="f.cardNumber.errors.minlength">
                    Your card number is incomplete.
                  </div>
                </div>
              </div>
              <div class="expiry-date" style="flex: 10%">
                <div class="enabled">
                  <input
                    style="
                      font-size: 20px !important;
                      font-family: 'Montserrat', sans-serif !important;
                    "
                    *ngIf="!isInputClicked"
                    class="fon_size"
                    type="text"
                    name="expiryMonth"
                    formControlName="expiryMonth"
                    mask="00"
                    class="form-control text-right"
                    id="expiryMonth"
                    placeholder="MM"
                  />
                </div>
                <!--disabled month -->
                <input
                  style="
                    font-size: 20px !important;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  *ngIf="isInputClicked"
                  [attr.disabled]="isDisabled"
                  class="fon_size"
                  type="text"
                  name="expiryMonthDisabled"
                  formControlName="expiryMonthDisabled"
                  class="form-control text-right"
                  id="expiryMonthDisabled"
                  placeholder="MM"
                />
                <!--disabled month -->
                <div
                  class="text-left text-danger"
                  *ngIf="
                    (f.expiryMonth.touched && f.expiryMonth.errors) ||
                    (f.expiryMonth.errors && submitted && !isInputClicked)
                  "
                >
                  <div *ngIf="f.expiryMonth.errors.required">
                    This is <strong>required</strong>
                  </div>
                  <div
                    *ngIf="f.expiryMonth.errors.min || f.expiryMonth.errors.max"
                  >
                    Please input 01 - 12
                  </div>
                </div>
              </div>

              <div class="expiry-date expiryYear" style="flex: 10%">
                <span>
                  <div class="enabled">
                    <input
                      style="
                        font-size: 20px !important;
                        font-family: 'Montserrat', sans-serif !important;
                      "
                      *ngIf="!isInputClicked"
                      class="fon_size"
                      type="text"
                      name="expiryYear"
                      formControlName="expiryYear"
                      mask="00"
                      class="form-control"
                      id="expiryYear"
                      placeholder="YY"
                    />
                  </div>
                  <!-- disabled year -->
                  <input
                    style="
                      font-size: 20px !important;
                      font-family: 'Montserrat', sans-serif !important;
                    "
                    *ngIf="isInputClicked"
                    [attr.disabled]="isDisabled"
                    class="fon_size"
                    type="text"
                    name="expiryYearDisabled"
                    formControlName="expiryYearDisabled"
                    class="form-control"
                    id="expiryYearDisabled"
                    placeholder="YY"
                  />
                  <!-- disabled year -->
                </span>
                <div
                  class="text-left text-danger"
                  *ngIf="
                    (f.expiryYear.touched && f.expiryYear.errors) ||
                    (f.expiryYear.errors && submitted && !isInputClicked)
                  "
                >
                  <div *ngIf="f.expiryYear.errors.required">
                    This is <strong>required</strong>
                  </div>
                  <div *ngIf="f.expiryYear.errors.min">
                    Please input >= {{ minExpiryYear }}
                  </div>
                </div>
              </div>
              <div class="cvc" style="flex: 11%;">
                <div class="enabled">
                  <input
                    style="
                      font-size: 20px !important;
                      font-family: 'Montserrat', sans-serif !important;
                    "
                    *ngIf="!isInputClicked"
                    class="fon_size"
                    type="text"
                    name="cvc"
                    formControlName="cvc"
                    class="form-control"
                    id="cvc"
                    mask="000||0000"
                    placeholder="CVC"
                  />
                </div>
                <!--disabled cvc -->
                <input
                  style="
                    font-size: 20px !important;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  *ngIf="isInputClicked"
                  [attr.disabled]="isDisabled"
                  class="fon_size"
                  type="text"
                  name="cvcDisabled"
                  formControlName="cvcDisabled"
                  class="form-control"
                  id="cvcDisabled"
                  placeholder="CVC"
                />
                <!--disabled cvc -->
                <div
                  class="text-left text-danger"
                  *ngIf="
                    (f.cvc.touched && f.cvc.errors) ||
                    (f.cvc.errors && submitted && !isInputClicked)
                  "
                >
                  <div *ngIf="f.cvc.errors.required">
                    This is <strong>required</strong>
                  </div>
                  <div *ngIf="f.cvc.errors.minlength">
                    Please input 3 characters.
                  </div>
                </div>
              </div>
              <div class="zipCode" style="flex: 13%">
                <div class="enabled">
                  <input
                    style="
                      font-size: 20px !important;
                      font-family: 'Montserrat', sans-serif !important;
                    "
                    *ngIf="!isInputClicked"
                    class="fon_size"
                    type="text"
                    name="zipCode"
                    formControlName="zipCode"
                    class="form-control"
                    id="zipCode"
                    mask="00000"
                    placeholder="ZIP"
                  />
                </div>
                <!--  disabled zipcode -->
                <input
                  style="
                    font-size: 20px !important;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  *ngIf="isInputClicked"
                  [attr.disabled]="isDisabled"
                  class="fon_size"
                  type="text"
                  name="zipCodeDisabled"
                  formControlName="zipCodeDisabled"
                  class="form-control"
                  id="zipCodeDisabled"
                  placeholder="ZIP"
                />
                <!-- disabled zipcode -->
                <div
                  class="text-left text-danger"
                  *ngIf="
                    (f.zipCode.touched && f.zipCode.errors) ||
                    (f.zipCode.errors && submitted && !isInputClicked)
                  "
                >
                  <div *ngIf="f.zipCode.errors.required">
                    This is <strong>required</strong>
                  </div>
                  <div *ngIf="f.zipCode.errors.minlength">
                    Please input 5 characters.
                  </div>
                </div>
              </div>

              <div
                class="editBtnDiv d-flex align-items-center justify-content-center p-0"
                style="
                  flex: 9%;
                  margin-left: 5px !important;
                  border-radius: 20px;
                  height: 60px;
                "
              >
                <i
                  (click)="addNewCardInitializeForm()"
                  class="fa fa-edit"
                  style="font-size: 36px; color: #cdcdcd"
                ></i>
              </div>
            </div>

            <div
              class="text-note"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                style="width: 18px; margin-right: 5px"
                src="assets/icons/icon-locker.png"
                class="icon-locker"
                alt="icon-locker"
              />
              <span style="font-size: 14px"
                >All credit card data is securely processed through our payment
                partner</span
              >
              <img
                style="width: 34px; margin-left: 6px"
                src="assets/icons/icon-stripe.png"
                class="icon-stripe"
                alt="icon-stripe"
              />
            </div>
            <br />
            <h3
              class="upgradeSubscriptionHeading"
              style="font-size: 14px; padding-top: 9px; font-weight: 600"
            >
              A charge of ${{ amountShowedInModal }} will be applied today. Next
              payment will be charged on {{ NewDate }}
            </h3>
            <br />
            <div
              *ngIf="
                freeTrialdaysLeft <= 0 ||
                (user[0].DaysLeft <= 0 && user[0].DaysLeft != null)
              "
              class="containingButtons"
              style="padding-bottom: 48px"
            >
              <button
                (click)="updradePopup()"
                #closeUpgradeModal
                type="button"
                class="btn btn-outline-success m-2 rounded-pill"
                style="padding: 5px 36px !important"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <button
                (click)="onSubmitClick()"
                type="button"
                class="btn btn-success m-2 btn-lg rounded-pill"
                style="padding: 11px 36px"
              >
                Upgrade
              </button>
            </div>
              <div
                  *ngIf="(freeTrialdaysLeft > 0 && user[0].DaysLeft >0 )|| (freeTrialdaysLeft > 0 && user[0].DaysLeft == null) "
                  class="containingButtons" style="padding-bottom: 48px;">
                  <button (click)="onCancel()" #closeUpgradeModal type="button"
                    class="btn btn-outline-success m-2 rounded-pill" style="padding: 5px 36px !important;"
                    data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                  <button (click)="onSubmitClick()" type="button" class="btn btn-success m-2 btn-lg rounded-pill"
                    style="padding: 11px 36px;">Upgrade</button>
                </div>
                <div class="text-description" *ngIf="!discountPercent && !amountOff"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>