import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionDetail } from '../models/SubscriptionDetail';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient) { }

    public requestDataFromMultipleSources(userId): Observable<any[]> {
        let responseDashboardList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashboardStepsList?user_id=${userId}`);
        let responseProductList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardProductList?user_id=${userId}`);
        let responseIngredientList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardIngredientList?user_id=${userId}`);
        let responseMaterialList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardMaterialList?user_id=${userId}`);
        let responseActivityList = this.http.get<any>(`${environment.apiUrl}/Settings/GetActivityList?user_id=${userId}`);
        let responseInventoryUpdateList = this.http.get<any>(`${environment.apiUrl}/Settings/GetInventoryUpdateList?user_id=${userId}`);
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([
            responseDashboardList,
            responseProductList,
            responseIngredientList,
            responseMaterialList,
            responseActivityList,
            responseInventoryUpdateList
        ]);
    }
    public requestDataForDashboard(userId): Observable<any[]> {
        let responseDashboardList = this.http.get<any>(`${environment.apiUrl}/Settings/GetStartGuideStepsList?user_id=${userId}`);
        let responsePOList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardPurchaseOrderList?user_id=${userId}`);
        let responseLowStockList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardLowStockList?user_id=${userId}`);
        let responseMOList = this.http.get<any>(`${environment.apiUrl}/Settings/GetDashBoardManufacturingOrderList?user_id=${userId}`);
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([
            responseDashboardList,
            responsePOList,
            responseLowStockList,
            responseMOList,
        ]);
    }
}