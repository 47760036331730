import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { appInitializer } from './services/app-initializer';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataService } from './services/data.service';
import { PipesModule } from './pipes/pipes.module';
import { CompareValidatorDirective } from './directives/compare-validator.directive';
import { ToggleDirective } from './directives/toggle.directive';
import { ApiRequestInterceptor } from './interceptors/api-request.interceptor';

@NgModule({
  declarations: [
    CompareValidatorDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PipesModule
  ],
  exports: [    
    CompareValidatorDirective
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }
