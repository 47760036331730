import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { UpgradeLogoutComponent } from "src/app/upgrade-logout/upgrade-logout.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService, userSubject } from "src/app/core";
import { UtilService } from "src/app/core/services/util.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService } from "ngx-modal-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BILLING_CYCLE } from "src/app/core/constants";
import { SuccessSubscriptionPopupComponent } from "src/app/successSubscriptionPopup/successSubscriptionPopup.component";
import { Subscription } from "rxjs";
import * as menuData from "src/app/menu.json";
@Component({
  selector: "app-upgrade",
  templateUrl: "./upgrade.component.html",
  styleUrls: ["./upgrade.component.css"],
})
export class UpgradeComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ElementRef
  title = "prophit";
  user: any = [];
  isDashboard = false;
  toggleClass = false;
  toggleProfile = false;
  menuData: any[] = (menuData as any).default;
  subMenu = [];
  menuRouting: any;
  displayModal: boolean = false;
  displaySubscriptionModal: boolean = false;
  createdDate: Date;
  billingCycle = "";
  currentDate: Date;
  freeTrialdaysLeft: number;
  signupDate: Date;
  differenceInMilliseconds: number;
  event$;
  differenceInDays: number;
  amountShowedInModal: string;
  thirtyPercent: number;
  isDisabled: boolean = true;
  isInputClicked: boolean = false;
  upgradeSubscriptionForm: FormGroup;
  addNewCardForm: FormGroup;
  selectedBillingCycle: any = new Object() as any;
  promotionCode!: string;
  referralCode: string | null = null;
  billingCycleList = [];
  discountPercent = 0;
  amountOff = 0;
  minExpiryYear = Number(new Date().getFullYear().toString().substr(-2)) + 1;
  submitted: boolean;
  loading: boolean;
  isLoaded: boolean;
  timeInterval!: number;
  stripeCustomerId!: string;
  selectedPrice!: string;
  getFreeTrialInfo: any;
  zipCode!: string;
  defaultCardInfo: any;
  defaultCardNumber: string;
  displaySuccessModal: boolean = false;
  isPaymentFromDefaultCard: boolean = false;
  isFormLoaded: boolean = false;
  isFreeTrialValid: boolean = false;
  isPageInitialize: boolean = false;
  isInitialize!: boolean;
  thisIsAdmin: boolean = false;
  isLoadFirst: boolean = true;
  freeTrialExist: boolean = false;
  showUpgradeHeading: boolean = false;
  showUpgradeHeading1: boolean = false;
  openUpgradeModalSubscription: Subscription;
  NewDate: string;
  @ViewChild("closeUpgradeModal") closeUpgradeModal!: ElementRef;
  @ViewChild("successModal") successModal!: ElementRef;
  constructor(
    private dialogRef: MatDialog,
    public authService: AuthService,
    public utilService: UtilService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
   
    let user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    this.isPageInitialize = true;
    this.isInputClicked = true;
    if (user != null) {
      this.nullUserCheck();
      this.subscribeUserSubject();
    }
  }
  ngAfterViewInit(): void {
    this.modal.nativeElement.scrollTop = 0;
  }

  customizeLabel(labelObject: any) {
    var amount = labelObject.amount;
    var duration = labelObject.price_name;
    if (
      this.freeTrialdaysLeft <= 0 ||
      (this.user[0].DaysLeft <= 0 && this.user[0].DaysLeft != null) ||
      this.user[0].DaysLeft === undefined
    ) {
      var value = amount;
      if (duration === "Monthly") {
        var content = " Per Month";
        this.timeInterval = 1;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      if (duration == "Quarterly") {
        value = amount / 3;
        var content = " Per Month ";
        this.amountShowedInModal = (this.thirtyPercent * 3).toFixed(2);
        this.timeInterval = 3;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      if (duration == "Yearly") {
        value = amount / 12;
        var content = " Per Month ";
        this.amountShowedInModal = (this.thirtyPercent * 12).toFixed(2);
        this.timeInterval = 12;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      this.thirtyPercent = value;
      this.amountShowedInModal = (
        this.thirtyPercent * this.timeInterval
      ).toFixed(2);
      labelObject.nick_name = ` <div class="style-font12">${duration} (<span id="test02"><span>$${value}</span></span> ${content}) </div>`;
      return labelObject.nick_name;
    }

    if (
      this.freeTrialdaysLeft > 0 ||
      (this.user[0].DaysLeft > 0 && this.user[0].DaysLeft == null) ||
      this.user[0].DaysLeft != undefined
    ) {
      if (duration == "Daily") {
        return;
      }

      var value = amount;
      if (duration == "Monthly") {
        var content = "/mo for the first 3 months";
        this.timeInterval = 1;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      if (duration == "Quarterly") {
        value = amount / 3;
        var content = "/mo for the first 3 months";
        this.amountShowedInModal = (this.thirtyPercent * 3).toFixed(2);
        this.timeInterval = 3;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      if (duration == "Yearly") {
        value = amount / 12;
        var content = "/mo for the first 12 months";
        this.amountShowedInModal = (this.thirtyPercent * 12).toFixed(2);
        this.timeInterval = 12;
        this.NewDate = this.addMonths(this.timeInterval);
      }

      this.thirtyPercent = value - (value * 30) / 100;
      this.amountShowedInModal = (
        this.thirtyPercent * this.timeInterval
      ).toFixed(2);
      labelObject.nick_name = `<div class="style-font12"> ${duration} <span class="style-font12 strike"  id="test02" ><span class="txtBlack">$${value}
      </span></span> <strong class="color-green">$${this.thirtyPercent}</strong> ${content} </div>`;
      return labelObject.nick_name;
    }
  }

  initializeForm() {
    this.selectedBillingCycle = this.billingCycleList[0];
    this.upgradeSubscriptionForm = this.formBuilder.group({
      billingCycle: [this.selectedBillingCycle, [Validators.required]],
      cardName: [this.defaultCardInfo.cardName, [Validators.required]],
      cardNameDisabled: [this.defaultCardInfo.cardName],
      cardNumberWithoutMask: [this.defaultCardInfo.cardNumber],
      cardNumber: [
        this.defaultCardInfo.cardNumber,
        [Validators.required, Validators.minLength(15),Validators.maxLength(16)],
      ],
      expiryMonth: [
        this.defaultCardInfo.expMonth,
        [Validators.required, Validators.min(1), Validators.max(12)],
      ],
      expiryMonthDisabled: [this.defaultCardInfo.expMonth],
      expiryYear: [
        this.defaultCardInfo.expYear,
        [Validators.required, Validators.min(this.minExpiryYear)],
      ],
      expiryYearDisabled: [this.defaultCardInfo.expYear],
      cvc: ["", [Validators.required, Validators.minLength(3),]],
      cvcDisabled: ["xxxx"],
      zipCode: [
        this.defaultCardInfo.zipCode,
        [Validators.required, Validators.minLength(3)],
      ],
      zipCodeDisabled: [this.defaultCardInfo.zipCode],
    });
    this.isLoaded = true;
    this.cd.detectChanges();
  }

  populateRichTextOption(item) {
    if (item) {
      const billing = BILLING_CYCLE.find(
        (obj) =>
          obj.value.toLocaleLowerCase() === item.value.toLocaleLowerCase()
      );
      if (this.discountPercent || this.amountOff) {
        return `${
          billing?.value.charAt(0).toUpperCase() + billing?.value.slice(1)
        } (<span class="old-price">$${
          billing?.amount
        }</span> <strong  class="color-green">$${
          (Number(billing?.amount) * (100 - this.discountPercent)) / 100 -
          this.amountOff / 100
        } </strong> ${
          billing.value !== "yearly" ? "for first 3 months" : "per month"
        })`;
      }
      var result = this.customizeLabel(item);
      this.selectedPrice = item.id;
      return result;
    }
    return "";
  }

  onSubmitClick() {
    this.submitted = true;
    if (this.isInputClicked) {
      this.upgradeSubscriptionForm.get("cardName").clearValidators();
      this.upgradeSubscriptionForm.get("cardNumber").clearValidators();
      this.upgradeSubscriptionForm.get("expiryMonth").clearValidators();
      this.upgradeSubscriptionForm.get("expiryYear").clearValidators();
      this.upgradeSubscriptionForm.get("zipCode").clearValidators();
      this.upgradeSubscriptionForm.get("cvc").clearValidators();
      this.upgradeSubscriptionForm.controls["cardName"].setErrors(null);
      this.upgradeSubscriptionForm.controls["cardNumber"].setErrors(null);
      this.upgradeSubscriptionForm.controls["expiryMonth"].setErrors(null);
      this.upgradeSubscriptionForm.controls["cvc"].setErrors(null);
      this.upgradeSubscriptionForm.controls["expiryYear"].setErrors(null);
      this.upgradeSubscriptionForm.controls["zipCode"].setErrors(null);
      this.upgradeSubscriptionForm.updateValueAndValidity();
    }
    if (this.upgradeSubscriptionForm.invalid) {
      return;
    }
    this.loading = true;
    this.spinner.show();
    var formValues = this.upgradeSubscriptionForm.value;
    if (this.defaultCardNumber == formValues.cardNumberWithoutMask) {
      this.isPaymentFromDefaultCard = true;
    }
    var upgradeSubscription = {
      customerId: this.stripeCustomerId,
      price: this.selectedPrice,
      cardNumber: formValues.cardNumber,
      expMonth: formValues.expiryMonth,
      cvc: formValues.cvc,
      expYear: formValues.expiryYear,
      cardName: formValues.cardName,
      zipcode: formValues.zipCode,
      isPaymentFromDefaultCard: this.isPaymentFromDefaultCard,
      userId: this.user[0].id,
    };
    this.authService
      .upgradeSubscription(upgradeSubscription)
      .subscribe((url) => {
        this.closeUpgradesModal();
        this.freeTrialExist = false;
        let user = JSON.parse(localStorage.getItem("user"));
        localStorage.setItem("user", JSON.stringify(user));
        this.cd.detectChanges();
        this.modalService.show(SuccessSubscriptionPopupComponent);
        if (url) {
          window.location.href = url;
          return;
        }
        this.spinner.hide();
      });
  }

  get f() {
    return this.upgradeSubscriptionForm?.controls;
  }

  showUpgradeDialog() {
    this.isInputClicked = true;
    this.authService
      .GetDefaultCardInfo(this.stripeCustomerId)
      .subscribe((response) => {
        this.defaultCardInfo = response;
        this.defaultCardNumber = this.defaultCardInfo.cardNumber;
        this.initializeForm();
        this.upgradeSubscriptionForm.get("cardName").setValue("");
        this.upgradeSubscriptionForm.get("cardNumber").setValue("");
        this.upgradeSubscriptionForm.get("expiryMonth").setValue("");
        this.upgradeSubscriptionForm.get("expiryYear").setValue("");
        this.upgradeSubscriptionForm.get("zipCode").setValue("");
        this.upgradeSubscriptionForm.get("cvc").setValue("");
      });
    this.submitted = false;
  }

  AddCardOnSubmit() {
    var formValues = this.addNewCardForm.value;
    this.upgradeSubscriptionForm.get("cardName").setValue(formValues.cardName);
    this.upgradeSubscriptionForm
      .get("cardNumber")
      .setValue(formValues.cardNumber);
    this.upgradeSubscriptionForm
      .get("expiryMonth")
      .setValue(formValues.expiryMonth);
    this.upgradeSubscriptionForm
      .get("expiryYear")
      .setValue(formValues.expiryYear);
    this.upgradeSubscriptionForm.get("zipCode").setValue(formValues.zipCode);
    this.upgradeSubscriptionForm.get("cvc").setValue(formValues.cvc);
    this.addNewCardInitializeForm();
    this.cd.detectChanges();
  }

  onCancel() {
    if (this.isDashboard) {
      this.dialogRef.open(UpgradeLogoutComponent);
    }
  }

  subscribeUserSubject() {
    userSubject.subscribe((data) => {
      let user = data;
      if (user != null && user.length > 0) {
        this.thisIsAdmin = user[0].role === "admin" ? true : false;
        this.isPageInitialize = true;
        this.createdDate = user[0].created_date;
        this.stripeCustomerId = user[0].stripe_customer_id;
        this.getFreeTrialInfo = this.authService
          .getFreeTrialInfo(this.stripeCustomerId)
          .subscribe((res) => {
            
            let localstorageData = JSON.parse(localStorage.getItem("user"));
            let freeTrialFromlocalstorageData =
              localstorageData[0].is_free_trial;
            if (freeTrialFromlocalstorageData == true) {
              this.freeTrialExist = true;
            } else {
              this.freeTrialExist = false;
            }
          });
        this.signupDate = new Date(this.createdDate);
        this.currentDate = new Date();
        this.differenceInMilliseconds =
          this.currentDate.getTime() - this.signupDate.getTime();
        this.differenceInDays = Math.floor(
          this.differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );
        this.freeTrialdaysLeft = 14 - this.differenceInDays;
        //this.freeTrialdaysLeft=0;
        if (this.freeTrialdaysLeft <= 0) {
          this.isFreeTrialValid = false;
          this.showUpgradeHeading = true;
        }
      }
    });
  }

  addNewCardInitializeForm() {
   
    this.isInputClicked = false;
    this.addNewCardForm = this.formBuilder.group({
      cardName: ["", [Validators.required]],
      cardNumber: ["", [Validators.required, Validators.minLength(16)]],
      expiryMonth: [
        "",
        [Validators.required, Validators.min(1), Validators.max(12)],
      ],
      expiryYear: [
        "",
        [Validators.required, Validators.min(this.minExpiryYear)],
      ],
      cvc: ["", [Validators.required, Validators.minLength(3)]],
      zipCode: ["", [Validators.required, Validators.minLength(3)]],
    });
    this.isFormLoaded = true;
  }

  openDialog() {
    this.dialogRef.open(ConfirmPopupComponent);
  }

  updradePopup() {
    this.dialogRef.open(UpgradeLogoutComponent);
  }

  addMonths(months) {
    const date = new Date();
    date.setMonth(date.getMonth() + months);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  closeUpgradesModal(): void {
    this.closeUpgradeModal.nativeElement.click();
  }

  showSuccessDialog() {
    this.displaySuccessModal = true;
  }

  dropdownClicked(event: any) {
    console.log("Dropdown clicked!", event);
  }

  nullUserCheck() {
    this.isLoadFirst = false;
    this.route.queryParams.subscribe((param) => {
      this.billingCycle =
        param.billingCycle === undefined ? "" : param.billingCycle;
      this.promotionCode = param.promotion;
      this.authService.getBillingCycles(this.promotionCode).subscribe((res) => {
        this.discountPercent = res[1]?.ResponseData?.coupon.percent_off | 0;
        this.amountOff = res[1]?.ResponseData?.coupon.amount_off | 0;
        this.billingCycleList = res[0].prices;
        this.selectedPrice = this.billingCycleList[0].id;
        this.billingCycleList = this.billingCycleList.filter(
          (x) => x.price_name != "Daily"
        );
        this.billingCycleList = this.billingCycleList.map((item) => {
          const billing = BILLING_CYCLE.find(
            (obj) =>
              obj.value.toLocaleLowerCase() ===
              item.price_name.toLocaleLowerCase()
          );
          const bindingItem = {
            ...item,
            hasDiscount: this.discountPercent || this.amountOff,
            label: billing?.label,
            value: billing?.value,
          };
          bindingItem.labelInnerText =
            bindingItem.hasDiscount === 0
              ? this.populateRichTextOption(bindingItem)
              : this.customizeLabel(bindingItem);
          if (
            this.billingCycle &&
            this.billingCycle.toLocaleLowerCase() ===
              item.price_name.toLocaleLowerCase()
          ) {
            this.selectedBillingCycle = bindingItem;
          }
          return bindingItem;
        });
        this.showUpgradeDialog();
      });
    });
  }
}
