import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SubscriptionDetail } from "../core/models/SubscriptionDetail";

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}

  InsertUpdateFacilityLabour(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/Settings/InsertUpdateFacilityLabour`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  InsertUpdateMerchantFee(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/Settings/InsertUpdateMerchantFee`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetFacilityLabourList(UserId: number) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Settings/GetFacilityLabourList?user_id=${UserId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetMerchantFeeList(UserId: number) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Settings/GetMerchantFeeList?user_id=${UserId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  UpdatePassword(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/UpdatePassword`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getIndustryList() {
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/getIndustryList`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getContactInfo(userId: number) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Accounts/GetContactInfoListByUserId?user_id=${userId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getProductTypeList() {
    return this.http
      .get<any>(`${environment.apiUrl}/Settings/GetProductTypeList`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getSubscriptionDetails() {
    return this.http
      .get<any>(`${environment.apiUrl}/Transaction/Fetch_Subscription_Details_by_User`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  SavePaymentTransaction(model) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/Transaction/transactionDetals_insert`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  CancelPaymentTransaction(model) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/Transaction/CancelPaymentTransaction`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getPaymentTrasactionDetails(userId) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Transaction/Fetch_transaction_details?userid=${userId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  getSubscriptionDetailsByUser(userId) {
    return this.http
      .get<SubscriptionDetail[]>(
        `${environment.apiUrl}/Account/Fetch_Subscription_Details_by_User?userid=${userId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getCardDetails(customerId) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/stripe-payment/get-default-card-info?customerId=${customerId}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetPortalManagementUrl(customerId, action) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/stripe-payment/create-portal-management-url/${customerId}?type=${action}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetCustomerPortalSession(model) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/stripe-payment/create-customer-portal-session`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  uploadImage(file: File) {
    let formData = new FormData();
    formData.append("file", file);
    return this.http
      .post<any>(`${environment.apiUrl}/Settings/UploadImage`, formData)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetBillingSubscriptions(subcriptionId) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          `Basic ` +
          btoa(
            `${environment.PAYPAL_SANDBOX_CLIENT_ID}:${environment.PAYPAL_SANDBOX_SECRET_CODE}`
          ),
      }),
    };
    return this.http
      .get<any>(
        `${environment.PAYPAL_SANDBOX_BASE_URL}/billing/subscriptions/${subcriptionId}`,
        httpOptions
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  cancelBillingSubscriptions(subcriptionId) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          `Basic ` +
          btoa(
            `${environment.PAYPAL_SANDBOX_CLIENT_ID}:${environment.PAYPAL_SANDBOX_SECRET_CODE}`
          ),
      }),
    };

    return this.http
      .post<any>(
        `${environment.PAYPAL_SANDBOX_BASE_URL}/billing/subscriptions/${subcriptionId}/cancel`,
        {},
        httpOptions
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  suspendBillingSubscriptions(subcriptionId) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          `Basic ` +
          btoa(
            `${environment.PAYPAL_SANDBOX_CLIENT_ID}:${environment.PAYPAL_SANDBOX_SECRET_CODE}`
          ),
      }),
    };

    return this.http
      .get<any>(
        `${environment.PAYPAL_SANDBOX_BASE_URL}/billing/subscriptions/${subcriptionId}/suspend`,
        httpOptions
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
}
