import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, DataService } from '../core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

const ERROR_MESSAGE: string = "Some error occured. Please try again later";
@Component({
  selector: 'app-getting-started-guide-modal',
  templateUrl: './getting-started-guide-modal.component.html',
  styleUrls: ['./getting-started-guide-modal.component.css']
})
export class GettingStartedGuideModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ElementRef
  userId: number;
  startGuideStepList:[] = [];
  childSteps:[] = [];
  completedSteps:number=0;
  totalSteps:number=0;
  progressPercentage:number;
  currStep:number=1;
  currLabel:string = '';
  constructor(private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<GettingStartedGuideModalComponent>,
    private dataService: DataService,
    private toaster: ToastrService,
    private router: Router
    ) {
    {
      if (this.authService.currentUser) {
        this.userId = this.authService.currentUser[0].id;
      }
    }

   }

  ngOnInit(): void {
    this.getStartGuideStepList();
  }
  ngAfterViewInit():void{
    this.modal.nativeElement.scrollTop = 0
  }

  getStartGuideStepList(){
    this.dataService.getStartGuideStepList(this.userId).subscribe(
      (res)=>{
        this.spinner.hide();
        if (
          res != null &&
          res.ResponseData.length > 0
        ) {
          this.startGuideStepList = res.ResponseData;
          this.calculateStartGuideSteps();
        }
        else{
          this.spinner.hide();
          this.toaster.error(ERROR_MESSAGE);
        }
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error(err);
      }
    );
  }

  calculateStartGuideSteps(){
    this.startGuideStepList.forEach((data:any)=>{
      this.totalSteps += data.TotalSteps;
      this.completedSteps += data.CompletedSteps;
      this.progressPercentage = (100 / this.totalSteps) * this.completedSteps;
    })
  }

  goToStep(no: number, data:any){
    switch(no){
      case 2: 
        this.currStep = 2;
        this.childSteps = data.childSteps;
        this.currLabel = data.LabelText;
        break;

      case 3:
        this.dialogRef.close();
        this.router.navigate([data.Routing])
        break;
      
      default:
        this.currStep = 1;
        this.childSteps = [];
        this.currLabel = '';
        break;

    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
