import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService, DataService } from "../core";
import { DashboardService } from "../core/services/dashboard.service";
import { SettingsService } from "../settings/settings.service";
import { MatDialog } from "@angular/material/dialog";
import { BsModalRef, BsModalService } from "ngx-modal-bootstrap";
import { AppComponent } from "../app.component";
import { UpgradeLogoutComponent } from "../upgrade-logout/upgrade-logout.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  user: any;
  userId: number;
  DashboardList = [];
  ActivityList = [];
  InventoryUpdateList = [];
  ProductList = []; 
  IngredientList = [];
  MaterialList = [];
  RemainingSteps: number;
  CompletedSteps: number;
  TotalSteps: number;
  progressPercentage: number;
  IsStepsCompleted = false;
  modalRef: BsModalRef = new Object() as BsModalRef;
  @ViewChild("modeltemplate") templateModal: TemplateRef<any>;
  isPageInitialize: boolean;
  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private modalService: BsModalService,
    private dialogRef: MatDialog,
    private dashboardServ: DashboardService,
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent,
    private toaster: ToastrService
  )
  {
    if (this.authService.currentUser) {
      this.user = this.authService.currentUser[0];
      this.userId = this.authService.currentUser[0].id;
    }
  }

  ngOnInit(): void {
    
    this.appComponent.ngOnInit()
    let user = JSON.parse(localStorage.getItem('user')); 
    this.isPageInitialize = true;
    //let localstorageData = JSON.parse(localStorage.getItem("user"));
    let freeTrialFromlocalstorageData = user[0].is_free_trial;
    let SubscriptionDaysLeft = user[0].DaysLeft;
    this.requestDataFromMultipleSources();
    if (this.appComponent.freeTrialdaysLeft <=0 && freeTrialFromlocalstorageData)
    {
      this.dialogRef.open(UpgradeLogoutComponent)
    }
    if (user[0].DaysLeft <=0 && user[0].DaysLeft != null) 
    { 
      
      this.dialogRef.open(UpgradeLogoutComponent)
    }
  }
  reloadPageData(){
    window.location.reload()
  }
  async requestDataFromMultipleSources() {
    this.DashboardList = [];
    this.ProductList = [];
    this.IngredientList = [];
    this.MaterialList = [];
    this.ActivityList = [];
    this.InventoryUpdateList = [];
    this.spinner.show();
    this.dashboardServ.requestDataFromMultipleSources(this.userId).subscribe(
      async (responseList) => {
        this.spinner.hide();
        if (
          responseList[0] != null &&
          responseList[0].ResponseData.length > 0
        ) {
          let obj: any = responseList[0].ResponseData;
          this.DashboardList = obj;
          this.CompletedSteps = this.DashboardList[0].CompletedSteps;
          this.RemainingSteps = this.DashboardList[0].RemainingSteps;
          this.TotalSteps = this.DashboardList[0].TotalSteps;
          this.progressPercentage = (100 / this.TotalSteps) * this.CompletedSteps;
          this.IsStepsCompleted = this.CompletedSteps == 12 ? true : false;
        }

        if (
          responseList[1] != null &&
          responseList[1].ResponseData.length > 0
        ) {
          this.ProductList = responseList[1].ResponseData;
        }

        if (
          responseList[2] != null &&
          responseList[2].ResponseData.length > 0
        ) {
          this.IngredientList = responseList[2].ResponseData;
          console.log(this.IngredientList)
        }

        if (
          responseList[3] != null &&
          responseList[3].ResponseData.length > 0
        ) {
          this.MaterialList = responseList[3].ResponseData;
        }
        if (
          responseList[4] != null &&
          responseList[4].ResponseData.length > 0
        ) {
          let obj: any = responseList[4].ResponseData;
          this.ActivityList = obj;
        
        }
        if (
          responseList[5] != null &&
          responseList[5].ResponseData.length > 0
        ) {
          let obj: any = responseList[5].ResponseData;
          this.InventoryUpdateList = obj;
        }
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error("Some error occured. Please try again later");
      }
    );
  }
}
