import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class InventoryService {

  private isClose = new Subject<boolean>();
  data$ = this.isClose.asObservable();

  sendData(data: boolean) {
    this.isClose.next(data);
  }



  constructor(private http: HttpClient) { }

  addIngredient(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Ingredient/InsertUpdateIngredient`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  purchaseOrderInsertUpdate(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/PurchaseOrder/Insert_Update`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  bulkPurchaseOrderInsert(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/PurchaseOrder/BulkPurchaseOrder/Insert`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  updatePurchaseOrderStatus(model: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/PurchaseOrder/updatePurchaseOrderStatus`,
        model
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getPurchaseOrder(userid, status: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/PurchaseOrder/Fetch_PurchaseOrder?user_id=${userid}&status=${status}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getPurchaseOrderById(id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/PurchaseOrder/PurchaseOrderById?id=${id}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getPurchaseOrderBySupplierId(supplierId: number){
    return this.http
    .get<any>(`${environment.apiUrl}/PurchaseOrder/PurchaseOrderBySupplierId?supplierId=${supplierId}`)
    .pipe(
      map((x) => {
        return x;
      })
    );
  }

  removePurchaseOrder(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/PurchaseOrder/DeletePurchaseOrder`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  stockUpdateIngredient(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Stock/Update_Ingredient`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  stockUpdateMaterial(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Stocks/Update_Materials`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  insertMaterial(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Material/Insert_Material`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  removeIngredient(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Ingredient/DeleteIngredient`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  removeMaterial(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Material/DeleteMaterial`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  uploadImage(file: File) {
    let formData = new FormData();
    formData.append("file", file);
    return this.http
      .post<any>(`${environment.apiUrl}/Settings/UploadImage`, formData)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getIngredientByUser(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Ingredient/GetIngredientNameListByUserId?user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getSuppliersByUser(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Contacts/Fetch_Suppliers?user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getCustomersByUser(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Contacts/Fetch_Customers?user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }


  public requestDataFromMultipleSources(user_id): Observable<any[]> {
    let responseIngredientNameList = this.http.get<any>(`${environment.apiUrl}/Ingredient/GetIngredientNameListByUserId?user_id=${user_id}`);
    let responseMaterialNameList = this.http.get<any>(`${environment.apiUrl}/Material/GetMaterialNameListByUserId?user_id=${user_id}`);

    return forkJoin([
      responseIngredientNameList,
      responseMaterialNameList]);
  }
  public requestDataForStockListing(user_id): Observable<any[]> {
    let responseIngredientNameList = this.http.get<any>(`${environment.apiUrl}/Stock/GetIngredientListByUserId?user_id=${user_id}`);
    let responseMaterialNameList = this.http.get<any>(`${environment.apiUrl}/Stock/GetMaterialListByUserId?user_id=${user_id}`);
  
    return forkJoin([
      responseIngredientNameList,
      responseMaterialNameList]);
  }
  getIngredientDetails(userid, id) {
    return this.http
      .get<any>(`${environment.apiUrl}/Ingredient/GetIngredientList?id=${id}&user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getMaterialDetails(userid, id) {
    return this.http
      .get<any>(`${environment.apiUrl}/Ingredient/GetMaterialList?id=${id}&user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  addMaterial(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Ingredient/InsertUpdateMaterial`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getMaterialByUser(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Material/GetMaterialNameListByUserId?user_id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getIngredientById(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Ingredient/GetIngredientById?id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getMaterialById(userid) {
    return this.http
      .get<any>(`${environment.apiUrl}/Material/Fetch_Material_By_Id?id=` + userid)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getLinkedMaterialpackaging(id) {
    return this.http
      .get<any>(`${environment.apiUrl}/Material/LinkedMaterialPackagings?id=` + id)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  GetLinkedRecipeProductDetailById(ingredient_id, user_id) {
    return this.http
      .get<any>(`${environment.apiUrl}/Ingredient/GetLinkedRecipeProductDetailById?ingredient_id=${ingredient_id}&user_id=${user_id}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getProductDetailsByIngredientId(ingredient_id) {

    return this.http
      .get<any>(`${environment.apiUrl}/Comparison/Fetch_product_details_by_ingredientId?ingredient_id=` + ingredient_id)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getRecipeDetailsByIngredientId(ingredient_id) {
    return this.http
      .get<any>(`${environment.apiUrl}/Comparison/Fetch_recipe_ingredient_details_by_ingredientId?ingredient_id=` + ingredient_id)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  swap_ingredient_insert_update(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Comparison/swap_ingredient_insert_update`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getComparisonCalulation(ingredient_id, ingredient_unit_price) {
    return this.http
      .get<any>(`${environment.apiUrl}/Comparison/Comparison_Calulation_For_Product?productId=0&IngredientId=${ingredient_id}&new_ingredient_unit_price=${ingredient_unit_price}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getIngredientBrandSupplierAutoComplete(searchText, type, category) {
    return this.http
      .get<any>(`${environment.apiUrl}/Accounts/FetchBrandSupplier?type=${type}&category=${category}&search_param=${searchText}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

}
