import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formArrayFilter'
})
export class FormArrayFilterPipe implements PipeTransform {

  transform(items: any[], keyName: any, searchKeyword: any): any[] {
    if(!items) return [];
    if(!searchKeyword) return items;

    searchKeyword = searchKeyword.toLowerCase();
    if (keyName == "all") {
      let filterKeys = Object.keys(items[0].value);
      return items.filter(item => {
        return filterKeys.some((keyName) => {
          return new RegExp(searchKeyword, 'gi').test(item.value[keyName]) || keyName == "";
        });
      });
    }
    return items.filter( item => {
      return new RegExp(searchKeyword, 'gi').test(item.value[keyName]) || keyName == "";//it.value.product_name.toLowerCase().includes(searchKeyword);
    });
  }

}