import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core';

@Component({
  selector: 'app-getting-started-guide-popup',
  templateUrl: './getting-started-guide-popup.component.html',
  styleUrls: ['./getting-started-guide-popup.component.css']
})
export class GettingStartedGuidePopupComponent implements OnInit {
  @ViewChild('popupModal') popupModal: ElementRef;
  dataSource:any;
  totalSteps:number = 0;
  completedSteps:number = 0;
  progressPercentage:number = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private dialogRef: MatDialogRef<GettingStartedGuidePopupComponent>,
  private dataService: DataService,
  private router: Router) {
    this.dialogRef.disableClose = false;
   }

  ngOnInit(): void {
    this.dataSource = this.data.dataSource;
    this.totalSteps = this.data.totalSteps;
    this.completedSteps = this.data.completedSteps;
    this.progressPercentage = this.data.progressPercentage;
  }

  goToRoute(){
    this.dialogRef.close();
    this.router.navigate([this.dataSource.Routing])
  }

  @HostListener('document:click', ['$event'])
  handleOnClick(event: Event): void {
    const clickedInside = this.popupModal.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.dialogRef.close();
      this.dataService.setStartGuideSource(false);
    }
  }

}
