import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();

  setData(data: any) {
    if (data) {
      this.dataSubject.next(data);

      if (data.type == "error" || data.type == "success") {
        setTimeout(() => {
          this.dataSubject.next(null);
        }, 1000);
      }
    } else {
      this.dataSubject.next(null);
    }
  }
}