<div class="modal-popup" #popupModal>
<div class="modal-content">
    <div class="header">
        <div class="header-text">
            <h4>Getting Started, Next Up</h4>
        </div>
        <div class="d-flex mt-3 align-items-center">
            <p-progressBar class="w-75 rounded-xl" [value]="progressPercentage" [showValue]="false">
            </p-progressBar>
            <p
              class="ml-4 text-center font-medium text-black text-16pt"
            >
              <span class="fw-semibold">{{ completedSteps }} of {{ totalSteps }}</span>
            </p>
          </div>
    </div>
    <div class="body mt-6">
        <div (click)="goToRoute()" class="step flex align-items-center justify-between p-3 cursor-pointer">
            <div class="flex">
              <p class="montserrat mt-1 font-medium font-semibold text-gray-brand text-16pt">
                {{ dataSource.LabelText }}
              </p>
            </div>
            <div class="px-2 py-1">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 12.5H3" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 6.5L21 12.5L15 18.5" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
          </div>
    </div>
</div>
</div>
