<div class="modal-backdrop">
  <div #modal class="modal" tabindex="-1" style="display: block !important">
    <div class="modal-dialog custom-alert">
      <div
        style="border: none; width: 600px; height: 650px"
        class="modal-content"
      >
        <div class="modal-body">
          <div class="d-flex" [class.justify-content-between]="currStep == 2" [class.justify-content-end]="currStep == 1">
            <div *ngIf="currStep == 2" (click)="goToStep(1, null)" class="d-flex align-items-center cursor-pointer back-button">
              <svg class="d-inline" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 18.5L9.5 12.5L15.5 6.5" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span class="text-brand-green ml-3">Back</span>
                
            </div>
            <div (click)="onClose()" class=""
              style="
                padding: 5px;
                background-color: rgb(241, 241, 241);
                border-radius: 10px;
              "
            >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L20 20" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 20L20 4" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              
              
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <div class="header flex">
                    <div class="svg-image">
                      <svg
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.5449 72.1641C20.5449 65.5366 25.9175 60.1641 32.5449 60.1641H74.871C81.4984 60.1641 86.871 65.5366 86.871 72.1641V100.664H20.5449V72.1641Z"
                          fill="#7DDDB5"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <rect
                          x="39.3262"
                          y="43.7344"
                          width="29.3478"
                          height="35.2174"
                          rx="4"
                          fill="white"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <rect
                          width="35.8043"
                          height="9.3913"
                          rx="4.69565"
                          transform="matrix(1 0 0 -1 35.8047 78.9531)"
                          fill="white"
                        />
                        <path
                          d="M41.0873 69.5618H40.5003C37.907 69.5618 35.8047 71.6641 35.8047 74.2575V74.2575C35.8047 76.8508 37.907 78.9531 40.5003 78.9531H66.9134C69.5067 78.9531 71.609 76.8508 71.609 74.2575V74.2575C71.609 71.6641 69.5067 69.5618 66.9134 69.5618V69.5618"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <rect
                          x="35.8047"
                          y="43.7344"
                          width="35.8043"
                          height="9.3913"
                          rx="4.69565"
                          fill="white"
                        />
                        <path
                          d="M41.0873 53.1257H40.5003C37.907 53.1257 35.8047 51.0234 35.8047 48.43V48.43C35.8047 45.8367 37.907 43.7344 40.5003 43.7344H66.9134C69.5067 43.7344 71.609 45.8367 71.609 48.43V48.43C71.609 51.0234 69.5067 53.1257 66.9134 53.1257V53.1257"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <circle
                          cx="46.9562"
                          cy="50.1906"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="51.6534"
                          cy="53.714"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="58.1085"
                          cy="52.5421"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="61.0441"
                          cy="57.8234"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="45.7824"
                          cy="59.5812"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="55.7609"
                          cy="66.0421"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="61.63"
                          cy="73.6671"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <circle
                          cx="47.5441"
                          cy="72.4952"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                        <path
                          d="M20.5449 76.2734C20.5449 69.646 25.9175 64.2734 32.5449 64.2734H74.871C81.4984 64.2734 86.871 69.646 86.871 76.2734V104.773H20.5449V76.2734Z"
                          fill="#D5F9EE"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <path
                          d="M20.5449 73.0781H22.7623C26.076 73.0781 28.7623 75.7644 28.7623 79.0781V89.9694C28.7623 93.2831 26.076 95.9694 22.7623 95.9694H20.5449V73.0781Z"
                          fill="#7DDDB5"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <path
                          d="M66.3262 73.3203C66.3262 72.2157 67.2216 71.3203 68.3262 71.3203H69.0218C70.1264 71.3203 71.0218 72.2157 71.0218 73.3203V95.7334C71.0218 96.8379 70.1264 97.7334 69.0218 97.7334H68.3262C67.2216 97.7334 66.3262 96.8379 66.3262 95.7334V73.3203Z"
                          fill="#7DDDB5"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <rect
                          x="63.9785"
                          y="80.7109"
                          width="4.69565"
                          height="9.3913"
                          rx="2.34783"
                          transform="rotate(-90 63.9785 80.7109)"
                          fill="white"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <path
                          d="M86.8711 73.0781H84.6537C81.34 73.0781 78.6537 75.7644 78.6537 79.0781V89.9694C78.6537 93.2831 81.34 95.9694 84.6537 95.9694H86.8711V73.0781Z"
                          fill="#7DDDB5"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <path
                          d="M33.457 94.7969H38.1527"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M25.8262 32.5781L30.5218 37.2738"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M30.5215 32.5781L25.8258 37.2738"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M47.5436 25.2422C46.6632 25.8291 44.9023 27.4726 44.9023 29.3509C44.9023 31.2291 46.2719 32.09 46.9567 32.2857C48.3263 32.7748 50.0089 34.8096 45.7828 39.0357"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M54.2936 25.2422C53.4132 25.8291 51.6523 27.4726 51.6523 29.3509C51.6523 31.2291 53.0219 32.09 53.7067 32.2857C55.0763 32.7748 56.7589 34.8096 52.5328 39.0357"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M61.3366 25.2422C60.4562 25.8291 58.6953 27.4726 58.6953 29.3509C58.6953 31.2291 60.0649 32.09 60.7497 32.2857C62.1192 32.7748 63.8018 34.8096 59.5757 39.0357"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M42.8496 94.7969H47.5453"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M15.2617 104.773H92.153"
                          stroke="#1B8E56"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <circle
                          cx="85.1086"
                          cy="35.5129"
                          r="2.93478"
                          stroke="#1B8E56"
                          stroke-width="2"
                        />
                        <circle
                          cx="17.0226"
                          cy="50.1906"
                          r="1.76087"
                          fill="#1B8E56"
                        />
                      </svg>
                    </div>
                    <div class="header-content mx-2 w-100 d-flex flex-column justify-content-end">
                      <div classs="content">
                        <svg class="d-inline" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 3.5H8H5C4.44771 3.5 4 3.94771 4 4.5V21C4 21.5523 4.44771 22 5 22H19C19.5523 22 20 21.5523 20 21V4.5C20 3.94771 19.5523 3.5 19 3.5H16.525H15.5" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.5 2H8.5V5H15.5V2Z" stroke="#333333" stroke-width="2" stroke-linejoin="round"/>
                            <path d="M9 12.5L11.3333 15L16 10" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                            
                        <span class="ml-1 font-normal font-semibold">Getting Started Guide</span>
                      </div>
                      <div class="d-flex mt-2 align-items-center">
                        <p-progressBar class="w-75 rounded-xl" [value]="progressPercentage" [showValue]="false">
                      </p-progressBar>
                      
                        <p
                          class="ml-4 text-center font-medium text-black text-16pt"
                        >
                          <span>{{ completedSteps }} of {{ totalSteps }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="currLabel != ''" class="label-text mt-4">
                    <h5>{{currLabel}}</h5>
                  </div>
              <div class="body pt-10">
                <ng-container *ngIf="currStep == 1">
                <div class="" *ngFor="let data of startGuideStepList; let i = index">
                <div (click)="goToStep(2, data)" class="step flex justify-between align-items-center p-2" [class.border-b]="i!=4">
                  <div class="flex align-items-center">
                    <svg *ngIf="!data.IsCompleted" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22.5C14.7614 22.5 17.2614 21.3807 19.0711 19.5711C20.8807 17.7614 22 15.2614 22 12.5C22 9.7386 20.8807 7.2386 19.0711 5.42893C17.2614 3.61929 14.7614 2.5 12 2.5C9.2386 2.5 6.7386 3.61929 4.92893 5.42893C3.11929 7.2386 2 9.7386 2 12.5C2 15.2614 3.11929 17.7614 4.92893 19.5711C6.7386 21.3807 9.2386 22.5 12 22.5Z" stroke="#CED4DA" stroke-width="2" stroke-linejoin="round"/>
                      </svg>
                      <svg *ngIf="data.IsCompleted" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22.5C14.7614 22.5 17.2614 21.3807 19.0711 19.5711C20.8807 17.7614 22 15.2614 22 12.5C22 9.7386 20.8807 7.2386 19.0711 5.42893C17.2614 3.61929 14.7614 2.5 12 2.5C9.2386 2.5 6.7386 3.61929 4.92893 5.42893C3.11929 7.2386 2 9.7386 2 12.5C2 15.2614 3.11929 17.7614 4.92893 19.5711C6.7386 21.3807 9.2386 22.5 12 22.5Z" fill="#2BB673" stroke="#2BB673" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M8 12.5L11 15.5L17 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        
                      
                    <!-- <i class="fa fa-check text-brand-green mt-2 mr-3 text-16pt" *ngIf="!item.IsButtonVisible"></i> -->
                    <p class="montserrat mt-1 ml-3 font-medium font-normal text-black text-16pt">
                      <span class="step-label">{{ data.LabelText }}</span>
                      <br/>
                      <span class="fw-light text-xs"> {{data.CompletedSteps}} of {{data.TotalSteps}} steps completed</span>
                    </p>
                  </div>
                  <div class="step-icon px-2 py-1">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 12.5H3" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 6.5L21 12.5L15 18.5" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                    
                  <!-- <button [routerLink]="item.Routing"
                    class="bg-brand-green py-1 w-48 lg:w-1/3 text-xs text-white rounded-2xl" *ngIf="item.IsButtonVisible">
                    {{ item.ButtonText }}
                  </button> -->
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="currStep == 2">
              <div class="" *ngFor="let data of childSteps; let i = index">
                <div (click)="goToStep(3, data)" class="step flex justify-between align-items-center py-3" [class.border-b]="i!=(childSteps.length-1)">
                  <div class="flex align-items-center">
                    <svg *ngIf="!data.IsCompleted" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22.5C14.7614 22.5 17.2614 21.3807 19.0711 19.5711C20.8807 17.7614 22 15.2614 22 12.5C22 9.7386 20.8807 7.2386 19.0711 5.42893C17.2614 3.61929 14.7614 2.5 12 2.5C9.2386 2.5 6.7386 3.61929 4.92893 5.42893C3.11929 7.2386 2 9.7386 2 12.5C2 15.2614 3.11929 17.7614 4.92893 19.5711C6.7386 21.3807 9.2386 22.5 12 22.5Z" stroke="#CED4DA" stroke-width="2" stroke-linejoin="round"/>
                      </svg>
                      <svg *ngIf="data.IsCompleted" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22.5C14.7614 22.5 17.2614 21.3807 19.0711 19.5711C20.8807 17.7614 22 15.2614 22 12.5C22 9.7386 20.8807 7.2386 19.0711 5.42893C17.2614 3.61929 14.7614 2.5 12 2.5C9.2386 2.5 6.7386 3.61929 4.92893 5.42893C3.11929 7.2386 2 9.7386 2 12.5C2 15.2614 3.11929 17.7614 4.92893 19.5711C6.7386 21.3807 9.2386 22.5 12 22.5Z" fill="#2BB673" stroke="#2BB673" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M8 12.5L11 15.5L17 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        
                      
                    <!-- <i class="fa fa-check text-brand-green mt-2 mr-3 text-16pt" *ngIf="!item.IsButtonVisible"></i> -->
                    <p class="montserrat mt-1 ml-3 font-medium font-normal text-black text-16pt">
                      <span class="step-label">{{ data.LabelText }}</span>
                    </p>
                  </div>
                  <div class="step-icon px-2 py-1">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 12.5H3" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 6.5L21 12.5L15 18.5" stroke="#2BB673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                    
                  <!-- <button [routerLink]="item.Routing"
                    class="bg-brand-green py-1 w-48 lg:w-1/3 text-xs text-white rounded-2xl" *ngIf="item.IsButtonVisible">
                    {{ item.ButtonText }}
                  </button> -->
                </div>
              </div>
            </ng-container>
                <!-- <input  placeholder="Company name"
                                 formControlName="company_name"
                                 class="form-control form-control-sm h-10 input-border" type="text"
                                                [ngClass]="{ 'is-invalid': submitted && supplierForm.controls.company_name.errors }"> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
