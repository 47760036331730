import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BsModalService } from "ngx-modal-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService, ConfirmDialogService } from "src/app/core";
import { FilterByPipe } from "src/app/core/pipes/filter-by.pipe";

@Component({
  selector: "app-your-modal",
  templateUrl: "./successSubscriptionPopup.component.html",
  styleUrls: ["./successSubscriptionPopup.component.css"],
})
export class SuccessSubscriptionPopupComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    
  ) {}

  ngOnInit(): void {
    this.cd.detectChanges();
   
  }
  hideModal() {
    this.modalService.hide();
  }
  showModal(){
    this.modalService.show(ConfirmDialogService);
  }
  gotoExternalDomain() {
    window.location.href = "https://www.prophitapp.com/feedback";
  }

  OnClickReload(){
    location.reload();
  }
}
