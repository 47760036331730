
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block !important">
      <div class="modal-dialog custom-alert" role="document">
        <div class="modal-content" style="width: 550px; margin-top: -100px;">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <div style="font-family: 'Montserrat', sans-serif; font-weight: 700;margin-bottom: 20px;">
                  Your renewal has been <br> canceled. You can still enjoy <br> your free trial before it ends.
                </div>
                <p class="confirm-button">
                  <a>
                    <button (click)="onDoneClick()" style="background-color: #2BB673; height: 36px; width: 90px; font-size: 13px !important; font-weight: 700 !important;" class="btn btn-success m-2 btn-lg rounded-pill">Done</button>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
