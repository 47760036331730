export enum resourceType{
    ingredient = 1,
    material
}

export enum packageType{
    Unit = 1,
    Cases,
    Pallets
}
export enum PoStatus{
    not_received = 1,
    received,
    partially_received
}