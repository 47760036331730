import { MobileLandingPageComponent } from "./mobile-landing-page/mobile-landing-page.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core";
import { SubscriptionGuard } from "./core/guards/subscription.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NewDashboardComponent } from "./new-dashboard/new-dashboard.component";

const accountModule = () =>
  import("./account/account.module").then((x) => x.AccountModule);
const settingsModule = () =>
  import("./settings/settings.module").then((x) => x.SettingsModule);
const productsModule = () =>
  import("./product/product.module").then((m) => m.ProductModule);
const inventoryModule = () =>
  import("./inventory/inventory.module").then((m) => m.InventoryModule);
const assemblyModule = () =>
  import("./assembly/assembly.module").then((m) => m.AssemblyModule);
const fulfillmentModule = () =>
  import("./fulfillment/fulfillment.module").then((m) => m.FulfillmentModule);
const contactsModule = () =>
  import("./contacts/contacts.module").then((m) => m.ContactsModule)
const adminModule = () =>
  import("./admin/admin.module").then((m) => m.SubscribersModule);
const routes: Routes = [
  {
    path: "account", loadChildren: accountModule},
  { path: "mobile-landing-page", component: MobileLandingPageComponent, pathMatch: "full"},
  { path: "", redirectTo: "/account/login", pathMatch: "full" },
  { path: "products", loadChildren: productsModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "fulfillment", loadChildren: fulfillmentModule, canActivate: [AuthGuard,SubscriptionGuard], },
  { path: "settings", loadChildren: settingsModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "inventory", loadChildren: inventoryModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "contacts", loadChildren: contactsModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "assembly", loadChildren: assemblyModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "admin", loadChildren: adminModule, canActivate: [AuthGuard,SubscriptionGuard] },
  { path: "dashboard", component: NewDashboardComponent, canActivate: [AuthGuard], },
  // { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard], },
  // { path: "new-dashboard", component: NewDashboardComponent, canActivate: [AuthGuard], },
  { path: "**", redirectTo: "account/login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top'
   })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
