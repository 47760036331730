import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mobile-landing-page",
  templateUrl: "./mobile-landing-page.component.html",
  styleUrls: ["./mobile-landing-page.component.scss"],
})
export class MobileLandingPageComponent {
  backToHome() {
    location.href = "https://www.prophitapp.com/";
  }
}
