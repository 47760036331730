import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
   user:any;
   renewalDate:any;
   formattedRenewalDate:any;

  constructor(private authService:AuthService,private datePipe:DatePipe ) {
    if (this.authService.currentUser) {
      this.user = this.authService.currentUser[0];
    }
    this.authService.user.subscribe((data) => {
      this.user = data[0];
      this.renewalDate=this.user.renewal_date;
      this.formattedRenewalDate = this.datePipe.transform(this.renewalDate, 'yyyy-MM-dd');
    });
   }
   isModalOpen = false;

  setModalOpen(open: boolean): void {
    this.isModalOpen = open;
  }

 isModalCurrentlyOpen():boolean
 {
 if (this.formattedRenewalDate <= this.getCurrentDate()) {
     this.setModalOpen(true);
            return true;
    } 
    else 
    {
       this.setModalOpen(false);
       return false;
   }
 }
  getCurrentDate() {
    const formattedCurrentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    return formattedCurrentDate;
  }
}
