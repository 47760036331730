import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToasterService } from "src/app/custom-toaster/toaster.service";
import { AuthService } from "src/app/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BsModalService } from "ngx-modal-bootstrap";
import { Subject } from "rxjs";
import { ModalService } from "../core/services/modal.service";
import { SettingsService } from "../settings/settings.service";
export const openUpgradeModal = new Subject<{isDashboard:boolean}>();
const RETURN_URL = `${environment.baseUrl}/dashboard`;
@Component({
  selector: "app-upgrade-logout",
  templateUrl: "./upgrade-logout.component.html",
  styleUrls: ["./upgrade-logout.component.css"],
})
export class UpgradeLogoutComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ElementRef
  user: any;
  toggleProfile = false;
  isDashboard = false;
  showToaster: boolean = false;
  userId: any;
  
  constructor(
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<UpgradeLogoutComponent>,
    private dialog: MatDialog,
    private modalService:ModalService,
    private toasterService: ToasterService,
    private settingsService: SettingsService,
  ) {
    if (this.authService.currentUser) {
      this.user = this.authService.currentUser[0];
      this.userId = this.authService.currentUser[0].id;
    }
  }

  ngOnInit(): void {
    this.modalService.setModalOpen(true);
    this.isDashboard = true;
  }
  ngAfterViewInit(): void{
    this.modal.nativeElement.scrollTop = 0;
  }
  CreateCustomerPortalSession() {
    if(this.user.role == 'user'){
      this.spinner.show();
      var model = {
        CustomerId: this.user.stripe_customer_id,
        ReturnUrl: RETURN_URL,
        UserId: this.user.user_id
      }
      this.settingsService
        .GetCustomerPortalSession(model)
        .subscribe((url) => {
          if (url) {
            window.location.href = url;
            return;
          }
          this.spinner.hide();
         
        },
        (err) => {
          this.errorMessage("user doesn't have any subscriptions to manage")
          this.spinner.hide();
        });
    }
    else{
      this.errorMessage("user doesn't have any subscriptions to manage")
    }
  }

  onDoneClick(): void {
    this.modalService.setModalOpen(false);
    this.dialogRef.close();
  }

  logout() {
    this.authService.logout();
  }
  successMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: "success" };
    this.toasterService.setData(data);
  }

  errorMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: "error" };
    this.toasterService.setData(data);
  }

  showToasterOnClick() {
    this.showToaster = true;
    setTimeout(() => {
      this.showToaster = false;
    }, 2000);
  }
  handleToasterClosed() {
    this.showToaster = false;
  }
}
