import { Component, Input, OnInit } from "@angular/core";
import { ConfirmDialogService } from "../core/services/confirm-dialog.service";
import { ConfirmPopupComponent } from "../account/confirm-popup/confirm-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "confirm-dialog.component.html",
  styleUrls: ["confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  message: any;
  constructor(
    private dialogRef: MatDialog,
    private confirmDialogService: ConfirmDialogService
  ) {}
  ngOnInit(): any {
    this.confirmDialogService.getMessage().subscribe((message) => {
      this.message = message;
    });
  }

  openDialog() {
    this.dialogRef.open(ConfirmPopupComponent);
  }
}
