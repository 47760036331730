import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
// import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import 'moment-duration-format';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  removeColumnList: any = ['TotalRecords', 'TotalPages'];
  refresh: BehaviorSubject<string> = new BehaviorSubject('');

  // public modalRef: BsModalRef;

  template: TemplateRef<any>;
  currentUser: any;

    options: moment.DurationFormatSettings = {
    forceLength: true,
    precision: 0,
    template: 'hh:mm:ss',
    trim: false
  };
  constructor(private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentPipe) { }

  getKeys(obj) {
    return Object.keys(obj)
  }

  getType(x) {
    return typeof x;
  }

  get TemplateRef() {
    return this.template;
  }

  set TemplateRef(template: TemplateRef<any>) {
    this.template = template;
  }

  get CurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  set CurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  integerOnly(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true)
    ) {
      return;
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }

  // Not Allow: Ctrl+V
  integerOnlyWithoutCtrlV(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }

  //allows decimal digit
  integerWithDecimalDigit(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  //allows decimal digit
  preventKey(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  
  //allows decimal digit
  preventZero(event) {
    const e = <KeyboardEvent>event;
    if (event.target.value === '0') {
      e.preventDefault();
    }
  }

  currencyFormat(value) {
    // return this.currencyPipe.transform(value, 'USD');
    return this.currencyPipe.transform(value, 'USD', undefined, '1.2-4');
  }

  percentFormat(value) {
    return Number(this.percentPipe.transform(value));
  }

  removeDollarSign(currency) {
    if(currency != null || undefined){
      return Number(currency.replace(/[^0-9\.]+/g, ""));
    }
  }

  removePercentSign(value) {
    const test = value;
    if (test.includes('%')) {
      return Number(value.replace('%', ""));
    }
  }

  addPercentSign(value) {
    const test = value;
    if (test.includes('%')) {
      return value;
    }
    else {
      return value + '%';
    }
  }

  convertTohour(totalTimeInSecond) {
    return (totalTimeInSecond / 60) / 60;
  }

  toHHMMSS(seconds) {
    if (seconds > 0) {
      // return (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
      // return new Date(seconds * 1000).toISOString().substr(11, 8);
      let duration = moment.duration(seconds, 'seconds');
      return duration.format('hh:mm:ss', 0, this.options);
    }
    else {
      let duration = moment.duration(0, 'seconds');
      return duration.format('hh:mm:ss', 0, this.options);
    }
  }

  toSecondsFromHHMMSS(hhmmss: string) {
    let totalTimeInSecond = 0;
    let totalTaskTime: string = hhmmss;
    if (hhmmss.includes(':')) {
      let splitHHMMSS = totalTaskTime.split(':')
      let hours = Number(splitHHMMSS[0]);
      let minutes = Number(splitHHMMSS[1]);
      let seconds = Number(splitHHMMSS[2]);
      totalTimeInSecond = (hours * (3600 / 1)) + (minutes * (60 / 1)) + seconds;
      totalTimeInSecond = moment.duration(hhmmss).asSeconds();

    }
    else {
      let hours = Number(totalTaskTime.substring(0, 2));
      let minutes = Number(totalTaskTime.substring(2, 4));
      let seconds = Number(totalTaskTime.substring(4, 6));
      totalTimeInSecond = (hours * (3600 / 1)) + (minutes * (60 / 1)) + seconds;
    }
    return totalTimeInSecond;
  }

  calcPercentage(partialValue, totalValue) {
    let perCentage = 0;
    if (partialValue == 0 && totalValue == 0) {
    } else {
      perCentage = (100 * partialValue) / totalValue;
    }

    return perCentage;
  }

  renameFile(originalFile, newName) {
    
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
  }

  removeTrailingZeros(value) {
    value = value.toString();

    // # if not containing a dot, we do not need to do anything
    if (value.indexOf('.') === -1) {
      return value;
    }

    // # as long as the last character is a 0 or a dot, remove it
    while ((value.slice(-1) === '0' || value.slice(-1) === '.') && value.indexOf('.') !== -1) {
      value = value.substr(0, value.length - 1);
    }
    return value;
  }

  sortByDateDescending(a,b){  
    var dateA = new Date(a.created_date);
    var dateB = new Date(b.created_date);
    return dateB > dateA ? 1 : -1;  
}
}
