import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subscription, forkJoin } from "rxjs";
import { map, tap, delay, finalize } from "rxjs/operators";
import { ApplicationUser } from "../models/application-user";
import { environment } from "src/environments/environment";

export const userSubject: BehaviorSubject<any> = new BehaviorSubject<any>("");

@Injectable({
  providedIn: "root",
})

export class AuthService implements OnDestroy {
  



  addNewCard(addNewCardForm: { customerId: string; price: string; cardNumber: any; expMonth: any; cvc: any; expYear: any; isPaymentFromDefaultCard: boolean; }) {
    throw new Error("Method not implemented.");
  }

  public user: Observable<any>;
  public isMobileView = false;

  constructor(private router: Router, private http: HttpClient) {
    userSubject.next(
      JSON.parse(localStorage.getItem("user"))
    );
    this.user = userSubject.asObservable();

    const ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.isMobileView = true;
    }
  }

  ngOnDestroy(): void {}

  public get currentUser(): any {
    return userSubject.value;
  }

  login(email, password) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/GetUserLoginList`, {
        email_id: email,
        password: password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (user && user.length > 0) { 
            localStorage.setItem("user", JSON.stringify(user));
            userSubject.next(user);
          }
          return user;
        })
      );
  }
  loginByCheckoutSessionId(checkoutSessionId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/stripe-payment/login-user`, {
        CheckoutSessionId: checkoutSessionId,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setUserSubject(user)
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("user");
    userSubject.next(null);
    this.router.navigate(["/account/login"]);
  }

  register(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/InsertUpdateAccounts`, model)
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  registerUser(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/RegisterUser`, model)
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  createCheckoutSession(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/create-checkout-session`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  UpdateProfileDetails(model){
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/UpdateProfileDetails`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  UpdatePassword(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/UpdatePassword`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }
  
  UpdateEmail(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/UpdateEmail`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  UpdateGuideCompleted(model) {
    return this.http
      .post<any>(`${environment.apiUrl}/Accounts/UpdateGuideCompleted`, model)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }


  verifyEmail(token) {
    return this.http
      .get<any>(`${environment.apiUrl}/accounts/verifyEmail?token=${token}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  resendEmail(email) {
    return this.http
      .get<any>(`${environment.apiUrl}/accounts/resendEmail?email_id=${email}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  generateVerificationCode(emailId: string) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Accounts/GenerateVerificationCode?email_id=${emailId}`                    //forgot-password
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

 
  // generateVerificationOTPCode(emailId, firstName, lastName) {
  //   return this.http.get<any>(`${environment.apiUrl}/Accounts/GenerateVerificationOTPCode?email_id=${emailId}&user_first_name=${firstName}&user_last_name=${lastName}`,  //email-OTP
                  
  //   ).pipe(
  //     map((x) => {
  //       return x;
  //     })
  //   );
  // }
  generateVerificationOTPCode(emailId) {
    return this.http.get<any>(`${environment.apiUrl}/Accounts/GenerateVerificationOTPCode?email_id=${emailId}`,  //email-OTP
                  
    ).pipe(
      map((x) => {
        return x;
      })
    );
  }

  VerificationOTPCode(emailId, emailPasscode) {
    
    return this.http.get<any>(`${environment.apiUrl}/Accounts/VerificationOTPCode?email_id=${emailId}&emailPasscode=${emailPasscode}`,  //email-OTP
                  
    ).pipe(
      map((x) => {
        return x;
      })
    );
  }

 
  getVerificationionCode(vCode: string) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Accounts/GetVerificationionCode?verification_code=${vCode}`
      )
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  getMasterData(): Observable<any[]> {
    let foodCategoryResponse = this.http.get<any>(
      `${environment.apiUrl}/masterdata/food-beverage-categories`
    );
    let countryResponse = this.http.get<any>(
      `${environment.apiUrl}/masterdata/countries`
    );

    return forkJoin([foodCategoryResponse, countryResponse]);
  }

  getBillingCycles(promotionCode) {
    let billingCycleResponse = this.http.get<any>(
      `${environment.apiUrl}/stripe-gateway/get-billing-cycles`
    );
    let promotionResponse: Observable<any>;
    if (!promotionCode) {
      promotionResponse = of(null);
    } else {
      promotionResponse = this.http.get<any>(
      `${environment.apiUrl}/stripe-gateway/promotion-code/${promotionCode}/validate`
      );
    }
    return forkJoin([billingCycleResponse, promotionResponse]);
  }

  getStateList(countryId): Observable<any[]> {
    return this.http.get<any>(
      `${environment.apiUrl}/masterdata/country-states/${countryId}`
    );
  }

  checkEmail(email): Observable<any[]> {
    return this.http.get<any>(
      `${environment.apiUrl}/stripe-gateway/validate-user?email=${email}`
    );
  }

  checkPromotionCode(promotionCode): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/stripe-gateway/promotion-code/${promotionCode}/validate`
    );
  }

  // registerWithStripe(data) {
  //   return this.http.post<any>(
  //     `${environment.apiUrl}/stripe-payment/register-user`,
  //     data
  //   );
  // }

  registerWithStripe1(data, ref: string) {
    const apiUrl = `${environment.apiUrl}/stripe-payment/register-user?ref=${ref}`;
    return this.http.post<any>(apiUrl, data);
  }

  setUserSubject(user) {
    localStorage.setItem("user", JSON.stringify(user));
    userSubject.next(user);
  }

  GetPortalManagementUrl(customerId, action) {
    return this.http
      .get<any>(`${environment.apiUrl}/stripe-payment/create-portal-management-url/${customerId}?type=${action}`)
      .pipe(
        map((x) => {
          return x;
        })
      );
  }

  upgradeSubscription(upgradeSubscription:any): Observable<any> { 
    
    return this.http.post(`${environment.apiUrl}/stripe-payment/upgrade-subscription`,upgradeSubscription);
}

getFreeTrialInfo(customerId:string): Observable<any> {
  return this.http.post(`${environment.apiUrl}/Accounts/getFreeTrialInfo` + '?customerId=' + customerId,'');
}

GetDefaultCardInfo(customerId:string){
  return this.http.get(`${environment.apiUrl}/stripe-payment/get-default-card-info` + '?customerId=' + customerId);

}
getCardDetails(customerId:string){
  return this.http.get(`${environment.apiUrl}/stripe-payment/get-default-card-info` + '?customerId=' + customerId);
}

getUserIp(): Observable<string> {
  return this.http.get<string>(`${environment.apiUrl}/Accounts/GetUserIp`);
}


insertIpAddress(ip_address: any) {
  return this.http
    .post<any>(`${environment.apiUrl}/Accounts/InsertUpdateIpAddress`, { ip_address }) 
    .pipe(
      map((x) => {
        return x;
      })
    );
}

}
