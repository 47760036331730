<!DOCTYPE html>
<html>

<body>
  <alert></alert>
  <app-confirm-dialog></app-confirm-dialog>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white">Please wait</p>
  </ngx-spinner>

  <div class="conatainer m-0 p-0">
    <div *ngIf="user" class="row m-0 p-0">
      <div class="col-2 ">
        <div
        class="col-2 flex flex-col text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 fixed top-0 left-0 z-50"
        style="overflow-x: scroll;height: 108%; ">
        <div class="flex-shrink-0 px-8 py-4 flex flex-col items-center justify-between mt-2">
          <a>
            <svg id="Component_109" data-name="Component 109" xmlns="http://www.w3.org/2000/svg" width="150"
              height="33.42" viewBox="0 0 150 33.42" style="margin: 0 auto">

              <g id="Logo" transform="translate(1667.093 -1764.38)">
                <path id="Path_16" data-name="Path 16"
                  d="M-1647.848,1800.872a8.886,8.886,0,0,0-1.89-2.81,8.961,8.961,0,0,0-2.8-1.882,8.542,8.542,0,0,0-3.454-.7h-11.1v21.533h4.764v-4.04h5.553a12.257,12.257,0,0,0,4.2-.666,8.229,8.229,0,0,0,3.012-1.832,7.8,7.8,0,0,0,1.8-2.744,9.318,9.318,0,0,0,.615-3.4A8.535,8.535,0,0,0-1647.848,1800.872Zm-4.3,5.09a4.23,4.23,0,0,1-.732,1.4,3.449,3.449,0,0,1-1.281.963,4.356,4.356,0,0,1-1.832.362h-6.335V1799.7h5.821a4.586,4.586,0,0,1,1.977.406,4.6,4.6,0,0,1,1.426,1.057,4.59,4.59,0,0,1,.883,1.484,4.691,4.691,0,0,1,.312,1.68A5.561,5.561,0,0,1-1652.149,1805.962Z"
                  transform="translate(0 -21.909)" fill="#bfbfbf" />
                <path id="Path_17" data-name="Path 17"
                  d="M-1577.433,1808.989a8.648,8.648,0,0,0,1.412-4.931,9.218,9.218,0,0,0-.63-3.454,8.007,8.007,0,0,0-1.752-2.722,7.813,7.813,0,0,0-2.708-1.774,9.391,9.391,0,0,0-3.5-.63h-11.317v21.533h4.764v-4.547h4.714c.948,1.361,1.9,2.7,2.853,4.011.13.181.261.354.391.536h5.734q-1.847-2.509-3.729-5A7.841,7.841,0,0,0-1577.433,1808.989Zm-3.707-3.1a4.15,4.15,0,0,1-.9,1.31,3.384,3.384,0,0,1-1.238.782,3.789,3.789,0,0,1-1.34.261h-6.545V1799.7h6.545a3.685,3.685,0,0,1,2.006.478,3.585,3.585,0,0,1,1.151,1.158,4.328,4.328,0,0,1,.543,1.426,7.727,7.727,0,0,1,.13,1.3A4.355,4.355,0,0,1-1581.14,1805.89Z"
                  transform="translate(-48.093 -21.909)" fill="#bfbfbf" />
                <path id="Path_18" data-name="Path 18"
                  d="M-1499.063,1795.4a13.48,13.48,0,0,0-2.887-4.282,13.616,13.616,0,0,0-4.267-2.9,13,13,0,0,0-5.247-1.063,12.954,12.954,0,0,0-5.21,1.063,13.617,13.617,0,0,0-4.267,2.9,13.616,13.616,0,0,0-2.887,4.282,13.052,13.052,0,0,0-1.063,5.224,13,13,0,0,0,1.063,5.247,13.627,13.627,0,0,0,2.827,4.207l.06.06a13.591,13.591,0,0,0,4.267,2.888,12.959,12.959,0,0,0,5.21,1.063,13.009,13.009,0,0,0,5.247-1.063,13.59,13.59,0,0,0,4.267-2.888l.06-.06a13.49,13.49,0,0,0,2.827-4.207,13,13,0,0,0,1.063-5.247A13.052,13.052,0,0,0-1499.063,1795.4Zm-20.2,1.877a9.153,9.153,0,0,1,1.824-2.812,8.808,8.808,0,0,1,2.684-1.93,7.565,7.565,0,0,1,3.309-.731,7.558,7.558,0,0,1,3.3.731,8.926,8.926,0,0,1,2.691,1.93,9.118,9.118,0,0,1,1.817,2.812,8.561,8.561,0,0,1,.671,3.347,8.51,8.51,0,0,1-.671,3.37,9.515,9.515,0,0,1-1.817,2.819,8.839,8.839,0,0,1-2.691,1.945,7.66,7.66,0,0,1-3.3.724,7.667,7.667,0,0,1-3.309-.724,8.724,8.724,0,0,1-2.684-1.945,9.553,9.553,0,0,1-1.824-2.819,8.646,8.646,0,0,1-.671-3.37A8.7,8.7,0,0,1-1519.26,1797.274Z"
                  transform="translate(-97.55 -16.286)" fill="#bfbfbf" />
                <path id="Path_19" data-name="Path 19"
                  d="M-1413.062,1800.872a8.878,8.878,0,0,0-1.889-2.81,8.994,8.994,0,0,0-2.809-1.882,8.518,8.518,0,0,0-3.454-.7h-11.092v21.533h4.764v-4.04H-1422a12.3,12.3,0,0,0,4.207-.666,8.288,8.288,0,0,0,3.012-1.832,8,8,0,0,0,1.8-2.744,9.313,9.313,0,0,0,.608-3.4A8.647,8.647,0,0,0-1413.062,1800.872Zm-4.308,5.09a4.093,4.093,0,0,1-.731,1.4,3.367,3.367,0,0,1-1.274.963,4.39,4.39,0,0,1-1.839.362h-6.328V1799.7h5.821a4.554,4.554,0,0,1,1.969.406,4.549,4.549,0,0,1,1.433,1.057,4.587,4.587,0,0,1,.884,1.484,4.846,4.846,0,0,1,.3,1.68A5.562,5.562,0,0,1-1417.369,1805.962Z"
                  transform="translate(-160.938 -21.909)" fill="#2bb673" />
                <path id="Path_20" data-name="Path 20"
                  d="M-1344.1,1795.478v10.788h-12.28v-10.788h-4.764v21.533h4.764v-6.524h12.28v6.524h4.764v-21.533Z"
                  transform="translate(-209.763 -21.909)" fill="#2bb673" />
                <path id="Path_21" data-name="Path 21" d="M-1275.077,1795.59v21.5h4.764v-21.5Z"
                  transform="translate(-268.922 -21.984)" fill="#2bb673" />
                <path id="Path_22" data-name="Path 22"
                  d="M-1249.263,1795.478v4.221h6.886v17.312h4.764V1799.7h6.886v-4.221Z"
                  transform="translate(-286.366 -21.909)" fill="#2bb673" />
                <circle id="Ellipse_5" data-name="Ellipse 5" cx="2.308" cy="2.308" r="2.308"
                  transform="translate(-1611.667 1764.38)" fill="#2bb673" />
              </g>
            </svg>
          </a>
          <button class="rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline">
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
              <path x-show="!open" fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
              <path x-show="open" fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <nav class="flex-grow md:block px-4 pb-4 md:pb-0">
          <!-- <div class="ml-4 ">
            <button class="h-9" style="width: 200px;">
              <i class="pr-1 pi pi-plus-circle"></i> New
            </button>
          </div> -->
          <div class="mt-4">
            <span *ngFor="let menu of menuData; let i = index">
              <div style="padding-bottom: 5px;" class="menu-item">
                <div style="padding: 12px, 0px, 12px, 0px; margin-top: 5px;"
                  *ngIf="menu.subMenu.length == 0 && isAdmin(menu.role) && menu.menuName =='Settings'">
                  <hr style="color: #CED4DA;">
                </div>
                <button style="margin-left: 0px;" [routerLink]="menu.menuRouting" (click)="onSubMenu(i)"
                  [routerLinkActive]="menu.menuActive"
                  *ngIf="menu.subMenu.length == 0 && isAdmin(menu.role) && menu.menuName!=='Settings'"
                  class="row flex items-center justify-between px-4 py-2 mt-2 text-base font-semibold montserrat rounded-lg sidebar-text">

                  <div style="display: flex; align-items: center;">
                    <img *ngIf="!menu.isActive" style="height: 25px; margin-left: -8px;" id="menu-icon"
                      [src]="menu.menuIcon" alt="">
                    <img *ngIf="menu.isActive" style="height: 25px; margin-left: -8px;" id="menu-icon"
                      [src]="menu.activeIcon" alt="">

                    <div
                      style="font-size: 16px; margin-left: 10px; width: 165px !important; font-weight: 500; font-family: Montserrat; display: flex; align-items: center;"
                      class="col-8">
                      {{ menu.menuName }}
                    </div>
                  </div>
                </button>
                <button style="margin-left: 0px;" [routerLink]="menu.menuRouting" [routerLinkActive]="menu.menuActive"
                  (click)="onSubMenu(i)"
                  *ngIf="menu.subMenu.length == 0 && isAdmin(menu.role) && menu.menuName=='Settings'"
                  class="row flex items-center justify-between px-4 py-2 mt-2 text-base font-semibold montserrat rounded-lg sidebar-text">
                  <div style="display: flex; align-items: center;">
                    <img *ngIf="!menu.isActive" style="height: 25px; margin-left: -8px;" id="menu-icon"
                      [src]="menu.menuIcon" alt="">
                    <img *ngIf="menu.isActive" style="height: 25px; margin-left: -8px;" id="menu-icon"
                      [src]="menu.activeIcon" alt="">
                    <div
                      style="font-size: 16px; margin-left: 10px; width: 165px !important; font-weight: 500; font-family: Montserrat; display: flex; align-items: center;"
                      class="col-8">
                      {{ menu.menuName }}
                    </div>
                  </div>
                </button>
                <div class="relative " [ngClass]="{ 'active-main-manu-item': menu.showSubMenu}"
                  [routerLinkActive]="menu.menuActive" *ngIf="menu.subMenu.length > 0 && isAdmin(menu.role)"
                  style=" width: 250px; border-radius: 8px;">
                  <button style="width: 250px;" (click)="onSubMenu(i)" id="mainMenu{{ i }}"
                    class="flex justify-between w-full px-4 py-2 mt-2 text-base font-semibold rounded-lg text-left sidebar-text ">
                    <div class="flex">
                      <img *ngIf="!menu.isActive" style="height: 25px;" id="menu-icon" [src]="menu.menuIcon" alt="">
                      <img *ngIf="menu.isActive" style="height: 25px;" id="menu-icon" [src]="menu.activeIcon" alt="">
                      <div class="col-8"
                        style="display: inline-block; font-size: 16px; font-family: Montserrat; font-weight: 500; margin-left: 10px;">
                        {{ menu.menuName }}
                      </div>
                      <div style="margin-left: 45px;">
                        <svg style="margin-top: 6px; width:7px ;margin-left: 6px;" *ngIf="!menu.showSubMenu" width="9"
                          height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.5 1L7.5 7L1.5 13" stroke="#343A40" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <svg style="margin-top: 7px;" *ngIf="menu.showSubMenu" width="14" height="9" viewBox="0 0 14 9"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 7.5L7 1.5L13 7.5" stroke="#1B8E56" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </button>
                  <div style="text-align: left; height: 115px; border-radius: 12px; padding: 0px, 16px, 16px, 16px;"
                    class="justify-between w-full px-4 text-base font-semibold rounded-lg dropdown" id="subMenu{{ i }}"
                    [hidden]="!menu.showSubMenu">
                    <span (click)="onSubMenuItemClick(smenu)" *ngFor="let smenu of menu.subMenu">
                      <button *ngIf="smenu.menuName ==='Recipes'"
                        style=" justify-content: start; width: 216px; height: 32px; padding-right: 112px; margin-top:15px;margin-bottom: -19px; margin-left: -6px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                      <button *ngIf="smenu.menuName ==='Purchase Orders'"
                        style=" justify-content: start; width: 216px; height: 32px;padding-right: 55px; margin-top:15px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                      <button *ngIf="smenu.menuName ==='Stock'"
                        style=" justify-content: start; width: 216px; height: 32px;padding-right: 135px; margin-top:15px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                      <button *ngIf="smenu.menuName ==='Materials'"
                        style=" justify-content: start; width: 216px; height: 32px;padding-right: 55%; margin-top:20px;margin-bottom: -19px; margin-left: -6px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                      <button *ngIf="smenu.menuName ==='Suppliers'"
                        style=" justify-content: start; width: 216px; height: 32px;padding-right: 50%; margin-top:20px;margin-bottom: -19px; margin-left: -6px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                      <button
                        *ngIf="smenu.menuName !== 'Recipes' && smenu.menuName !== 'Materials' && smenu.menuName !=='Suppliers' && smenu.menuName !=='Purchase Orders' && smenu.menuName !=='Stock'"
                        style=" justify-content: start; width: 216px; height: 32px; padding-right: 90px; margin-top:15px;margin-bottom: -19px; margin-left: -6px;"
                        [routerLink]="smenu.menuRouting" class="smenu-list"
                        [ngClass]="{ 'active-submenu-item': smenu.isActive}">
                        {{ smenu.menuName }}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div>
            <!-- <div *ngIf="!thisIsAdmin && freeTrialExist && freeTrialdaysLeft > 0"
              class="freeTrialMainDiv shadow p-3 mb-5 bg-white">
              <div class="popup_padding">
                <h4 class="freeTrialHeading">
                  {{ freeTrialdaysLeft }} days left in <br />your free trial
                </h4>
                <br />
                <p class="freeTrialParagraph">
                  Upgrade your plan
                  <br />before the free trial <br />ends to get 30% off!
                </p>
                <br />
                <button (click)="showUpgradeDialog()" class="btn btn-success m-2 btn-lg rounded-pill"
                  data-bs-toggle="modal" data-bs-target="#upgradeSubscription" style="padding: 12px">
                  Upgrade Now
                </button>
              </div>
            </div> -->
          </div>
        </nav>
      </div>
      </div>
      <div class="col-10 m-0 p-0">
        <section class="col-md-10" id="dashboard-header" style=" background-color: white; position: fixed;z-index: 9999;">
          <div class="flex justify-between" style="border-left: 2px solid #f1f1f1;">


            
            
            <div class="hidden lg:flex pl-5 align-items-center">
              <a class="block py-2 mt-2 text-gray-brand montserrat rounded-lg" href="https://www.prophitapp.com/updates"
                target="_blank">Updates</a>
              <a class="block pl-5 py-2 mt-2 mr-2 text-gray-brand montserrat rounded-lg"
                href="https://www.prophitapp.com/help" target="_blank">Help</a>
              <div class="pt-2 relative mx-auto text-gray-brand " style="width:350px;">
              </div>
            </div>
            <div class="hidden lg:flex pr-5 align-items-center">
              <div class="hidden lg:flex align-items-center btn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.5 10C11.433 10 13 8.433 13 6.5C13 4.56701 11.433 3 9.5 3C7.567 3 6 4.56701 6 6.5C6 8.433 7.567 10 9.5 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.5 4C16.5 4 17.625 6.25 16.5 9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M20 2C20 2 22.25 6.05 20 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 20.4V21H17V20.4C17 18.1598 17 17.0397 16.5641 16.184C16.1806 15.4314 15.5686 14.8195 14.816 14.436C13.9603 14 12.8402 14 10.6 14H8.4C6.1598 14 5.0397 14 4.18404 14.436C3.43139 14.8195 2.81947 15.4314 2.43598 16.184C2 17.0397 2 18.1598 2 20.4Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                <button type="button" class="ml-2" (click)="openFeedback()">Feedback & Response</button>
               
              </div>
              <div *ngIf="isStepsCompleted" (click)="openStartGuideDialog()" class="backIcon cursor-pointer mr-5 pl-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 3.5H8H5C4.44771 3.5 4 3.94771 4 4.5V21C4 21.5523 4.44771 22 5 22H19C19.5523 22 20 21.5523 20 21V4.5C20 3.94771 19.5523 3.5 19 3.5H16.525H15.5" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5 2H8.5V5H15.5V2Z" stroke="#333333" stroke-width="2" stroke-linejoin="round"/>
                  <path d="M9 12.5L11.3333 15L16 10" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
              </div>
              <div *ngIf="!isStepsCompleted" (click)="openStartGuideDialog()" class="backIcon cursor-pointer mr-2 pl-2">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.5 15.5H20H17C16.4477 15.5 16 15.9477 16 16.5V33C16 33.5523 16.4477 34 17 34H31C31.5523 34 32 33.5523 32 33V16.5C32 15.9477 31.5523 15.5 31 15.5H28.525H27.5" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M27.5 14H20.5V17H27.5V14Z" stroke="#333333" stroke-width="2" stroke-linejoin="round"/>
                  <path d="M21 24.5L23.3333 27L28 22" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="32" cy="16" r="5" fill="#2BB673" stroke="white" stroke-width="2"/>
                  </svg>
                  
              </div>
              <!-- <div class="backIcon mt-1 mr-5 pl-2">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.25 21C17.25 17.2721 20.2721 14.25 24 14.25C27.7279 14.25 30.75 17.2721 30.75 21V30.75H17.25V21Z"
                    stroke="#343A40" stroke-width="0.5" />
                  <path d="M17 31V21C17 17.134 20.134 14 24 14C27.866 14 31 17.134 31 21V31M14 31H34" stroke="#212529"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M24 34C25.3807 34 26.5 32.8807 26.5 31.5V31H21.5V31.5C21.5 32.8807 22.6193 34 24 34Z"
                    stroke="#212529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                  
              </div> -->
              <div class="relative showhim">
                <div class="flex align-items-center">

                  <div style="display: flex; align-items: center;">
                    <div style="width: 50%; border-right: 1px solid gainsboro; padding-right: 10px; height: 40px;">
                    </div>
                    <div style="width: 50%; padding-left: 10px;">
                    </div>
                  </div>
                  <div class="profile ">
                    <p class="text-white font-semibold text-center" style="padding: 0.8rem;"
                      *ngIf="!user[0]?.user_image_path">
                      {{user[0]?.user_short_name}}
                    </p>
                    <p class="text-white font-semibold text-center" *ngIf="user[0]?.user_image_path">
                      <img src="{{user[0]?.user_short_name}}" alt="avatar" class="avatar">
                    </p>
                  </div>
                  <button id="dropdownMenuButton"
                    class="mt-2 relative z-10 flex align-items-center rounded-md p-2 text-gray-brand montserrat rounded-lg focus:outline-none text-left">
                    <span style="color: black; font-weight: 600;">{{user[0]?.business_name}} <br/>
                      <span style="font-size: 14px;" class="text-muted">{{user[0]?.user_first_name}}&nbsp;{{user[0]?.user_last_name}}</span></span> 
                    <svg class="h-5 w-5 text-gray-800 ml-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
                <div style="width: 168px;z-index:9999;right: 19px;" class="absolute bg-white rounded shadow showme">
                  <a class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-brand montserrat rounded-lg sidebar-text"
                    (click)="yourprofile();toggleProfile = !toggleProfile">Your Profile</a>
                  <a class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-brand montserrat rounded-lg sidebar-text"
                    (click)="logout();toggleProfile = !toggleProfile">
                    Sign Out
                  </a>
                </div>
              </div>
            
            </div>
          </div>
        </section>
        <div class="container-fluid px-4 pb-4" style="margin-top: 3.3rem;">
          <router-outlet *ngIf="user"></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <!-- <section style="padding-left: 35px;" *ngIf="user">
    <div style="background-color:#F1F3F5;;" class="md:flex flex-col md:flex-row md:min-h-screen w-full">
    

      <section id="body" class="w-full" style="padding-left: 256px;">

        
      </section>

    </div>
  </section> -->
  <section *ngIf="!user">
    <router-outlet *ngIf="!user"></router-outlet>
  </section>
</body>

</html>