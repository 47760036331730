import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AuthService, DataService } from 'src/app/core';
import { ToasterService } from 'src/app/custom-toaster/toaster.service';

export const firstTimeSetup = new Subject<{ message: boolean }>();
const SUCCESS: string = "success";
const ERROR: string = "error";
const ERROR_MESSAGE: string = "Some error occured. Please try again later";
@Component({
  selector: 'app-set-up-profile-modal',
  templateUrl: './set-up-profile-modal.component.html',
  styleUrls: ['./set-up-profile-modal.component.css']
})
export class SetUpProfileModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ElementRef
  accountForm: FormGroup;
  submitted: boolean = false;
  step: number = 1;
  foodCategoryList = [];
  userId: number;
  isLoad: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private fb: FormBuilder,
    private authService: AuthService,
    private dialogRef: MatDialogRef<SetUpProfileModalComponent>,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private toasterService: ToasterService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.initializeAccountForm();
    this.getFoodCategoryList();
  }
  ngAfterViewInit():void{
    this.modal.nativeElement.scrollTop = 0
  }

  getFoodCategoryList(){
    this.authService.getMasterData().subscribe((responses) => {
      this.foodCategoryList = responses[0];
    });
  }

  initializeAccountForm(){
    this.accountForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", Validators.required],
      yourTitle: [""],
      businessName: ["", Validators.required],
      foodCategoryId: ["", Validators.required],
    });
    this.isLoad = true;
  }
  
  get f() {
    return this.accountForm.controls;
  }

  isValidStep2(){
    return (
      this.f.firstName.valid &&
      this.f.lastName.valid &&
      this.f.yourTitle.valid
    )
  }
  isValidStep3(){
    return (
      this.f.businessName.valid &&
      this.f.foodCategoryId.valid
    )
  }
  isDisabledCurrentStepButton(step: number){
    if(step === 1){
      return true;
    }
    else if(step === 2){
      return this.isValidStep2();
    }
    else{
      return this.isValidStep3();
    }
  }
  goToStep(step: number){
    switch(step){
      case 2:
        this.step = 2;
        break;
      case 3: 
        this.step = 3;
        break;
      case 4:
        this.onSubmit();
        break;
      default:
        this.step = 1;
        break;
    }

  }
  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    if (!this.isValidStep2() || !this.isValidStep3()) {
      return;
    }

    var model = {
      user_id: this.userId,
      user_first_name: this.f.firstName.value,
      user_last_name: this.f.lastName.value,
      user_title: this.f.yourTitle.value,
      business_name: this.f.businessName.value,
      food_category_id: this.f.foodCategoryId.value

    };
    this.spinner.show();
    this.authService.UpdateProfileDetails(model).subscribe(
      (resp) => {
        if(resp[0].ins_upd_flag === 'Y'){
        this.spinner.hide();
        let user = JSON.parse(localStorage.getItem("user"));
        user[0].user_first_name = model.user_first_name
        user[0].user_last_name = model.user_last_name
        user[0].user_title = model.user_title
        user[0].business_name = model.business_name
        localStorage.setItem("user", JSON.stringify(user));
        this.authService.setUserSubject(user);
        this.successMessage("Profile Updated successfully");
        setTimeout(() => {
          firstTimeSetup.next({ message: true });
          this.dialogRef.close();
        this.renderer.removeClass(document.body, 'no-scroll');
        }, 1000);
        }
        else{
          this.spinner.hide();
          this.errorMessage(ERROR_MESSAGE);
        }
      },
      (err) => {
        this.errorMessage(ERROR_MESSAGE);
        this.spinner.hide();
      }
    );
  }

  successMessage(message) {
    const data = { message: message, type: SUCCESS };
    this.toasterService.setData(data);
  }

  errorMessage(message) {
    const data = { message: message, type: ERROR };
    this.toasterService.setData(data);
  }

}
