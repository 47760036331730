import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '../services/modal.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {

  constructor( private router: Router,private modalService:ModalService)
    {

    }
  canActivate() {
    var checkModalStatus=this.modalService.isModalCurrentlyOpen();
    if(checkModalStatus==true)
      {
        this.router.navigate(['dashboard']);
        return false;
      }
     return true;
  }
  
}


