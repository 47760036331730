<div *ngIf="isDataLoaded" class="modal-backdrop">
    <div class="modal" #modal tabindex="-1" role="dialog" style="display:block!important">
        <div class="modal-dialog custom-alert" role="document">
            <div class="modal-content" style="width: 205%; z-index: 999999;">
                <div  class="modal-body">
                    <div class="row justify-content-end text-right">
                        <div style="padding: 0px; width: 40px;">
                            <svg (click)="onClose()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="4" fill="#F1F3F5"/>
                                <path d="M8 8L24 24" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 24L24 8" stroke="#343A40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p style="font-weight: bold; font-size: 24px;  margin-top: 22px; margin-bottom: 30px;"
                                class=" confirm-message">You’ve used your allotted orders for the month</p>
                                <p style="font-size: 16px;" class="mb-2">{{modalContent}}</p>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <p class="confirm-button" style="margin-top: 10px !important; margin-bottom: 20px !important;">
                            <a (click)="onClose()" class="mr-2.5">
                                <button style="height: 36px; width: 130px; margin-top: 22px; font-size:14px !important; font-weight:700 !important;"
                                    type="button" class="m-2 rounded-pill custom-button">Cancel</button>
                            </a>
                            <a (click)="onUpgrade()">
                                <button style="height: 36px; background-color: #028AFC; width: 130px; margin-top: 22px; font-size:14px !important; font-weight:700 !important;"
                                    class="text-white rounded-full w-2/5 h-8">Upgrade</button>
                            </a>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

