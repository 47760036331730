<!-- dashboard body -->
<div class="dashboard-body mt-12">
  <button #streamEndMessage hidden data-bs-toggle="modal" data-bs-target="#streamEndPopup"></button>
  <div class="dashboard-text">
    <p class="text-gray-brand text-2xl font-semibold" *ngIf="!IsStepsCompleted">
      Hi, {{ user?.user_first_name }}! Welcome to your dashboard.
    </p>

    <p class="text-gray-brand text-2xl font-semibold" *ngIf="IsStepsCompleted">
      Congratulations {{ user?.user_first_name }}, you are all setup!
    </p>
  </div>
  <div class="grid grid-col-1 lg:grid-cols-3 mt-8 gap-3">
    <div class="col-span-2" *ngIf="!IsStepsCompleted">
      <div class="bg-white rounded-xl w-full">
        <div class="p-7 flex justify-between w-full border-b">
          <p class="montserrat font-medium text-gray-brand text-18pt">
            Let's Get Started
          </p>
          <div class="flex w-1/2 mt-1">
            <p class="w-48 font-medium text-brand-green text-xs">
              {{ RemainingSteps }} Steps Remaining
            </p>
            <mat-progress-bar class="mt-2" mode="determinate" [value]="progressPercentage">
            </mat-progress-bar>
          </div>
        </div>
        <div class="grid grid-col-1 lg:grid-cols-3 gap-3 mt-8 px-6 pb-8">
          <div class="col-span-1">
            <div>
              <p class="montserrat font-semibold text-gray-brand text-sm">
                Set your Business Up for Prophit
              </p>
              <p class="montserrat font-medium text-gray-brand text-xs mt-2">
                Create your first product to calculate your profit and prepare
                for fullfillment.
              </p>
            </div>
            <div class="positionRelative mt-3 d-flex align-items-center justify-content-center float-start">
              <round-progress [current]="CompletedSteps" [max]="TotalSteps" [color]="'#2BB673'" [background]="'#eaeaea'"
                [radius]="125" [stroke]="15" [semicircle]="false" [rounded]="true" [clockwise]="true"
                [responsive]="false" [duration]="800" [animation]="'easeInOutQuart'" [animationDelay]="0"
                style="width: 90px; height: 90px">
              </round-progress>
              <div class="percentageValue">
                {{ CompletedSteps }}/{{ TotalSteps }}
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="bg-steps border-brand-green border rounded-lg py-3 px-4 mb-2"
              *ngFor="let item of DashboardList">
              <div class="flex justify-between">
                <div class="flex">
                  <div class="bullet-border mt-3 mr-2" *ngIf="item.IsButtonVisible"></div>
                  <i class="fa fa-check text-brand-green mt-2 mr-3 text-16pt" *ngIf="!item.IsButtonVisible"></i>
                  <p class="montserrat mt-1 font-medium text-gray-brand text-16pt">
                    {{ item.LabelText }}
                  </p>
                </div>
                <button [routerLink]="item.Routing"
                  class="bg-brand-green py-1 w-48 lg:w-1/3 text-xs text-white rounded-2xl" *ngIf="item.IsButtonVisible">
                  {{ item.ButtonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Calculate Fullfillment & Latest Ingredients -->
    <div class="col-span-1" *ngIf="IsStepsCompleted">
      <div class="relative bg-green-500 rounded-xl w-full mb-3 h-2/5">
        <div class="absolute inset-x-0 bottom-0 grid grid-cols-3 gap-3 px-4 py-4">
          <div class="col-span-2">
            <p class="text-white text-2xl font-semibold uppercase">
              Calculate Fullfillment
            </p>
          </div>
          <div>
            <button
              class="absolute right bottom-7 border-solid bg-black rounded-full w-24 h-8 shadow text-xs text-white"
              routerLink="/fulfillment">
              Fullfill Now
            </button>
          </div>
        </div>
      </div>
      <div class="margin-top-30 bg-white rounded-xl w-full">
        <div class="p-7 flex items-center justify-between w-full border-b">
          <p class="text-gray-brand text-18pt font-medium">
            Latest Ingredients
          </p>
          <p class="text-sm font-medium">
            <a routerLink="/inventory/ingredientlist" class="text-brand-green">View All ({{ IngredientList[0].Total
              }})</a>
          </p>
        </div>
        <div class="px-1 py-1">
          <table class="w-full table-size-col">
            <tbody>
              <tr class="w-full border-b text-xs text-left montserrat text-gray-500"
                *ngFor="let item of IngredientList">
                <td class="pt-1 pb-1">
                  <img [src]="item.image_path" alt="img" class="bg-gray-300 h-9 w-11 rounded" />
                </td>
                <td class="truncate">
                  {{ item.ingredient_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="py-2 px-6 text-center">
          <button class="text-brand-green bg-green-500 text-white rounded-full w-36 h-8 shadow text-xs"
            routerLink="/inventory/ingredientlist">
            View All Ingredients
          </button>
        </div>
      </div>
    </div>
    <!-- Latest Materials & Latest Products -->
    <div class="col-span-1" *ngIf="IsStepsCompleted">
      <div class="bg-white rounded-xl w-full mb-3">
        <div class="p-7 flex items-center justify-between w-full border-b">
          <p class="text-gray-brand text-18pt font-medium">Latest Products</p>
          <p class="text-sm font-medium">
            <a routerLink="/products" class="text-brand-green">View All ({{ ProductList[0].Total }})</a>
          </p>
        </div>
        <div class="">
          <div class="p-3">
            <div class="grid grid-cols-3 gap-3">
              <div *ngFor="let item of ProductList">
                <img [src]="item.image_path" alt="img" class="bg-gray-300 h-20 w-15 rounded" />
                <p class="text-gray-brand text-xs font-medium mt-1 truncate">
                  {{ item.product_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="py-2 px-6 text-center">
          <button class="text-brand-green bg-green-500 text-white rounded-full w-36 h-8 shadow text-xs"
            routerLink="/products">
            View All Products
          </button>
        </div>
      </div>
      <div class="margin-top-30 bg-white rounded-xl w-full mb-3">
        <div class="p-7 flex items-center justify-between w-full border-b">
          <p class="text-gray-brand text-18pt font-medium">Latest Materials</p>
          <p class="text-sm font-medium">
            <a routerLink="/inventory/materiallist" class="text-brand-green">View All ({{ MaterialList[0].Total }})</a>
          </p>
        </div>
        <div class="px-1 py-1">
          <table class="w-full table-size-col">
            <tbody>
              <tr class="w-full border-b text-xs text-left montserrat text-gray-500 rounded"
                *ngFor="let item of MaterialList">
                <td class="pt-1 pb-1">
                  <img [src]="item.image_path" alt="img" class="bg-gray-300 h-9 w-11" />
                </td>
                <td class="truncate">
                  {{ item.material_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="py-2 px-6 text-center">
          <button class="text-brand-green bg-green-500 text-white rounded-full w-36 h-8 shadow text-xs"
            routerLink="/inventory/materiallist">
            View All Materials
          </button>
        </div>
      </div>
    </div>
    <!-- Recent Activity & Inventory Updates -->
    <div class="col-span-2 lg:col-span-1">
      <div class="bg-white rounded-xl w-full mb-3">
        <div class="p-7 flex justify-between w-full border-b">
          <p class="text-gray-brand text-18pt font-medium">Recent Activity</p>
        </div>
        <div class="">
          <div class="py-6 px-6">
            <span *ngIf="ActivityList.length == 0">
              <p class="text-gray-brand text-16pt font-semibold">No Activity</p>
              <p class="text-gray-brand text-16pt font-medium mt-3">
                Complete the tasks on the left to set up your account.
              </p>
            </span>
            <span *ngIf="ActivityList.length > 0">
              <div *ngFor="let item of ActivityList" class="mb-2">
                <p class="text-brand-green text-16pt font-medium">
                  {{ item.activity_header }}
                </p>
                <p class="text-gray-brand text-16pt font-medium mb-2">
                  {{ item.activity_desc }}
                </p>
                <p class="text-gray-400 text-16pt">{{ item.activity_time }}</p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="margin-top-30 bg-white rounded-xl w-full">
        <div class="p-7 flex justify-between w-full border-b">
          <p class="text-gray-brand text-18pt font-medium">Inventory Updates</p>
        </div>
        <div class="">
          <div class="py-6 px-6" *ngIf="InventoryUpdateList.length == 0">
            <p class="text-gray-brand text-16pt font-semibold">No Inventory</p>
            <p class="text-gray-brand text-16pt font-medium mt-3">
              Set up your ingredients and materials to see your inventory
              updates here.
            </p>
          </div>
          <div class="py-6 px-6" *ngIf="InventoryUpdateList.length > 0">
            <div *ngFor="let item of InventoryUpdateList" class="mb-2">
              <p class="text-brand-green text-16pt font-medium">
                {{ item?.activity_header }}
              </p>
              <p class="text-gray-brand text-16pt font-medium mb-2">
                {{ item?.activity_desc }}
              </p>
              <p class="text-gray-400 text-16pt">{{ item?.activity_time }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <button style="font-size: 30p;" (click)="OnClickMethod12">Click To see modal </button>
 -->

<!-- <ng-template #modeltemplate>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Modal</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×hti is </span>
       </button>
    </div>
    <div class="modal-body">
       This is a sample modal dialog box.
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
    </div>
 </ng-template> -->



<!-- <div appBsModal bsModal class="modal fade edit-school-modal show d-block post-view-large" id="create-post" tabindex="-1"
 aria-labelledby="create-post" aria-hidden="true" area-modal="true">
 <div class="modal-body">
     <h1 class="congratulationPopup">Congratulations, you're all <br> set up!</h1>
     <div class="containingButtons">
              </div>
 </div>
</div> -->

<!-- 
<div
  class="modal fade edit-school-modal delete-modal"
  id="streamEndPopup"
  tabindex="-1"
  aria-labelledby="streamEndPopup"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div
        class="modal-header border-0 justify-content-center position-relative"
      ></div>
      <div class="modal-body">
        <div>
          <h3 class="text-center fw-semibold">Live stream has ended</h3>
          <h5 class="text-center fw-semibold" style="margin-top: 30px">
            Full video will be uploaded to profile shortly.
          </h5>
        </div>
      </div>
      <button
        #streamEndModalClose
        hidden
        id="closeBtn"
        type="button"
        class="btn custom-btn primary_btn earning-btn fw_400 h_44 w-100 font_16 prev-step-btn prev-step"
        data-bs-dismiss="modal"
      ></button>
    </div>
  </div>
</div> -->