import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  authStorageKey =  "user";
  userTypeStorageKey = "user-type"

  constructor() {}

  /**
   * Get data in localStorage with field name
   */
  getData(fieldName: string): string {
    return localStorage.getItem(fieldName) || "";
  }

  /**
   * Set data for field name in localStorage
   */
  setData(fieldName: string, content: string): void {
    localStorage.setItem(fieldName, content);
  }

  /**
   * Clear data in localStorage
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * Remove data by field name
   */
  removeData(fieldName: string): void {
    localStorage.removeItem(fieldName);
  }

  /**
   * Set user after login
   */
  setAuthUser(user): void {
    this.setData(this.authStorageKey, JSON.stringify(user));
  }

  /**
   * Get an authenticated user
   */
  getAuthUser() {
    const data = this.getData(this.authStorageKey);
    
    if (data) {
      return JSON.parse(data)[0];
    }

    return null;
  }

  /**
   * Set user type
   */
  setUserType(type: string): void {
    this.setData(this.userTypeStorageKey, type);
  }

  /**
   * Get an authenticated user
   */
  getUserType(): string {
    return this.getData(this.userTypeStorageKey);
  }


  /**
   * Get token from local storage
   */
  getAuthToken(): string {
    return this.getAuthUser()?.user_token?.access_token;
  }

  /**
   * Clean Authentication infor
   */
  clearAuth(): void {
    this.removeData(this.authStorageKey);
  }

  /**
   * check role permission
   */
  checkRolePermission(expectedRoles: Array<string>) {
    let hasRole = false;
    for (const role of expectedRoles) {
      const roleFound = this.getUserType().toUpperCase() === role.toUpperCase();
      if (roleFound) {
        hasRole = true;
        break;
      }
    }
    return hasRole;
  }

}
